//Marketoutlook Controller

// angular.module('controllerModule')
app.controller('marketController', ['$scope', '$http', '$state', 'localStorageService', 'ngProgressFactory', 'Notification', 'mainAjax', '$ngConfirm', 'anchorSmoothScroll', '$location', '$stateParams', '$sce', '$window', function ($scope, $http, $state, localStorageService, ngProgressFactory, Notification, mainAjax, $ngConfirm, anchorSmoothScroll, $location, $stateParams, $sce, $window) {
    $scope.marketStatus = true;
    $scope.whatsAppWindow = $window.screen.width;
    $scope.sharePage = $location.absUrl().split('?')[0]
    $scope.leadForm = {};
    // Hold the view untill the required data's are loaded
    $scope.pageReady = false;

    // progress bar configuration
    $scope.progressbar = ngProgressFactory.createInstance();
    $scope.progressbar.setColor('#0CE713');


    
    //Load selected super headers header data and description data
    $scope.makeDynamic = function (product, index, swipeAction) {
        if (!!product) {
            if (index !== undefined) {
                let indexVal = (swipeAction === 'left') ? index + 1 : index - 1;
                if (product[indexVal]) {
                    $scope.SelectedProductName = product[indexVal].name;
                    $scope.productHeaders = product[indexVal];
                    $scope.mainTitleCont = product[indexVal].name;
                    $('.' + product[indexVal].super_header_id).click();
                } else {
                    $scope.SelectedProductName = product.name;
                    $scope.productHeaders = product;
                    $scope.mainTitleCont = product.name;
                }
            } else {
                $scope.SelectedProductName = product.name;
                $scope.productHeaders = product;
                $scope.mainTitleCont = product.name;
            }
        }
    }




    //Display raw html to page view
    $scope.trustAsHtml = function (string) {
        return $sce.trustAsHtml(string);
    };
    let gProductData = [];
    let fetchProductData = function (strategy) {
        try {
            let reqUrl = `${mainAjax.baseUrl}/cms/superheaders?productId=${strategy}`;
            let reqData = {
                method: 'GET',
                url: `${mainAjax.baseUrl}/cms/superheaders?productId=${strategy}`,
                headers: {
                    'Content-Type': 'application / json'
                },
            };
            mainAjax.resolveRequestData(reqData).then(function (result) {
                $scope.progressbar.complete();
                //result = result.json();
                gProductData = result.data;
                let data = result.data.data;
                $scope.productInfo = data;
                $scope.makeDynamic(data[0]);
                // show the view when the required data's are loaded
                // load first product info
                if ($scope.productInfo[0] !== undefined) {
                    $scope.pageReady = true;
                } else {
                    Notification.error({
                        message: 'Strategy not found, choose a different strategy'
                    });
                }
            }, function (error) {
                Notification.error({
                    message: 'Oops! something broken'
                });
            })
        } catch (e) {
            Notification.error({
                message: 'Oops! something broken'
            });
        }
    };
    fetchProductData(118);
    $scope.loadProducts = function () {
        try {
            let reqUrl = `${mainAjax.baseUrl}/cms/products`;
            let reqData = {
                method: 'GET',
                url: `${mainAjax.baseUrl}/cms/products`,
                headers: {
                    'Content-Type': 'application / json'
                },
            };
            mainAjax.resolveRequestData(reqData).then(function (result) {
                $scope.availableProduct = result.data.data;

                $scope.availableProducts = result.data.data;
                // $scope.$digest()
                // $scope.$apply();
    
                if($scope.availableProducts && $scope.availableProducts.length>0 && document.getElementById('selesctproduct')){
                    $(document).ready(function () {
                        console.log($('#selesctproduct').SumoSelect())
                          $('#selesctproduct').SumoSelect();
                        });
                }

            }, function (error) {
                Notification.error({
                    message: 'Oops! something broken'
                });
            })

        } catch (e) {
            console.log(e)
            Notification.error({
                message: 'Oops! something broken'
            });
        }
    };
    $scope.loadProducts()
    $scope.$on('loadStrategySwiper', function (ngRepeatFinishedEvent) {

        var swiper = new Swiper('.strategies-mobile-nav-container', {
            slidesPerView: '1',
            spaceBetween: 30,
            navigation: {
                nextEl: '.strategies-mobile-bottom-next',
                prevEl: '.strategies-mobile-bottom-prev',
            },
        });
    });

    $scope.hideNumber = function () {
        if ($scope.leadForm.mobileNumber) {
            let number = $scope.leadForm.mobileNumber;
            let totalCharacter = $scope.leadForm.mobileNumber.length;
            let char = '';
            for (let i = 0; i < totalCharacter; i++) {
                char += '*'
            }
            char = char.substring(0, totalCharacter - 2) + number.charAt(totalCharacter - 2) + number.charAt(totalCharacter - 1);
            return char;
        }
    }
    $scope.downloadPDF = function () {
        // var a = document.createElement("a");
        // document.body.appendChild(a);
        // a.style.cssText = "display: none";
        var filePath = 0;
        let reqData = {
            url: `${mainAjax.baseUrl}/download/download`,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            data: gProductData
        };
        mainAjax.downloadRequest(reqData).then(function (result) {
            $scope.fileURL = result.data.data
            $scope.filePath = `${mainAjax.baseUrl}/download/downloadFile?filePath=` + $scope.fileURL;
            var a = document.getElementById("downloadLink");
            a.href = `${mainAjax.baseUrl}/download/downloadFile?filePath=` + $scope.fileURL;
            a.click();
        }, function (error) {
            Notification.error({
                message: 'Oops! something broken'
            });
        });


    }
    $scope.download = function (urlFile) {
        let reqData = {
            url: `${mainAjax.baseUrl}/download/downloadFile`,
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
            params: {
                "filePath": urlFile
            }

        };
        mainAjax.downloadFile(reqData);
    }
    $scope.onchangeTest = function (that) {
        console.log('ss');
        console.log(that);
        $scope.selecteditems=that;
      //  console.log($scope.product.name);
    }
    $scope.onchangeProduct = function () {
        $scope.currentCatValue = productData;
        $scope.categoryValues = categoryValues;
        console.log( $scope.currentCatValue);
        console.log( $scope.categoryValues);
    }
    var toksd;
    async function getLoginToken() {
        // var loginData = {
        //     "email": "senthilnathan.k@kotak.com",
        //     "password": "Abnegation@123",
        //     "is_admin": 1
        // };
        var loginData = {
            email: "eyJlbWFpbCI6InNlbnRoaWxuYXRoYW4ua0Brb3Rhay5jb20iLCJwYXNzd29yZCI6IkFibmVnYXRpb25AMTIzIn0="
        }
        let reqData = {
            url: `${mainAjax.baseUrl}/login`,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            data: loginData
        };
         await mainAjax.resolveRequestData(reqData).then(function (result) {
            console.log(result);
            toksd = result.data.data.token
            console.log("This is the token : " + toksd);
            return toksd;      
        }, function (error) {
            Notification.error({
                message: 'Oops! something broken'
            });
        });
    }
    
    
        $scope.addNewLeadUser = async () =>  {
            await getLoginToken();
            var name =document.getElementById("userName").value;
            var mobile=document.getElementById("mobileNumber").value;
            var email= document.getElementById("email").value;
            var dataselect=document.getElementById("selesctproduct").value;
                    let lObjNewLeadUser = {
                        'name':name,
                        'phonenumber':mobile,
                        'email':email,
                        'product1':$scope.selecteditems[0] !=undefined?$scope.selecteditems[0]:null,
                        'product2':$scope.selecteditems[1] !=undefined?$scope.selecteditems[1]:null,
                        'product3':$scope.selecteditems[2] !=undefined?$scope.selecteditems[2]:null,
                        'product4':$scope.selecteditems[3] !=undefined?$scope.selecteditems[3]:null,
                        'product5':$scope.selecteditems[4] !=undefined?$scope.selecteditems[4]:null,
                        'product6':$scope.selecteditems[5] !=undefined?$scope.selecteditems[5]:null,
                        'product7':$scope.selecteditems[6] !=undefined?$scope.selecteditems[6]:null
                    }
                    console.log(lObjNewLeadUser);
           
                    //let sessionData = localStorage.getItem('kspswk_aa');
            
             let reqData = {
                 url: `${mainAjax.baseUrl}/product/createLead`,
                 method: 'POST',
                 headers: {
                     'Content-Type': 'application/json',
                     Authorization: toksd//"Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MjE1LCJsb2dnZWRJbiI6IjE1NDI5NzU5MjAwMzciLCJpYXQiOjE1NDI5NzU5MjB9.yX9C42Gz1aRNDr1QYyKCG1HpXtCa0vHP7mOd5uaHr-w"
                 },
                 data: lObjNewLeadUser
             };
             console.log(`${mainAjax.baseUrl}/product/createLead`);
             mainAjax.resolveRequestData(reqData).then(function (result) {
                 console.log(result);
               
             }, function (error) {
                 Notification.error({
                     message: 'Oops! something broken'
                 });
             });
     
         }

    $scope.getRandomIntOTP = function () {

            let min = 1000;
            let max = 9999;
            return Math.floor(Math.random() * (max - min + 1)) + min;
        }
        var getrandomNumber;
        
        $scope.nextOtppage = function () {

            getrandomNumber = $scope.getRandomIntOTP();
            var phoneNumber = document.getElementById("mobileNumber").value;
            //var typeProductVAl = document.getElementById('typeProductVAl').value;
    
       
                let details = {mobileNo: phoneNumber,  id:getrandomNumber }            
                    try {
                        let reqDataq = {
                            url: `${mainAjax.baseUrl}/cms/sms6`,
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                Authorization: toksd
                            },
                            data: {
                                details:btoa(JSON.stringify(details)),
                            }
                        };
                        mainAjax.resolveRequestData(reqDataq).then(function (res) {       
                            ($("#leadFrom").modal("hide"), $("#leadFromOtp").modal("show"))
                        }, function (error) {
                            if (error.status != 401) {                           
                            }        
                        });        
                    } catch (e) {
                        console.log('e', e);
                        if (e.status == 401) {
                            $state.go('login');
                        }
                    }
                
    
    
            // $.ajax({
            //     url: uRLPath,
            //     type: 'POST',
            //     success: function (succ) {
            //     },
            //     error: function (err) {
            //     }
            // });
            // ($("#leadFrom").modal("hide"), $("#leadFromOtp").modal("show"))
        }
        var timerOn = true;
        
        function timer(remaining) {
            
          var m = Math.floor(remaining / 60);
          var s = remaining % 60;
          
          m = m < 10 ? '0' + m : m;
          s = s < 10 ? '0' + s : s;
          var getTimer = document.getElementById('timer');
            if(!getTimer){
                //
            } else{
          document.getElementById('timer').innerHTML = m + ':' + s;

            }
          remaining -= 1;
          
          if(remaining >= 0 && timerOn) {
            setTimeout(function() {
                timer(remaining);
            }, 1000);
            return;
          }
        
          if(!timerOn) {
            // Do validate stuff here
            window.onload = function() {
                window.setTimeout(setDisabled, 1000);
            }
            return;
          }
          
          // Do timeout stuff here
          setTimeout(function() {
            document.getElementById('resendEnable').disabled = false;
        }, 1000);
        }
        timer(60);
    $scope.resendClick = function () {
            timer(60);
            setTimeout(function() {
                document.getElementById('resendEnable').disabled = true;
            }, 1000);
            $scope.nextOtppage();
            
        }
         
    $scope.leadFormValidation = function () {

            var e = document.getElementById("userName").value,
                t = document.getElementById("mobileNumber").value,
                l = document.getElementById("email").value;
            "" != e && null != e || "" != t && null != t || "" != l && null != l ? "" == e || null == e ? document.getElementById("userName").style.borderColor = "#ff0000" : "" == t || null == t ? document.getElementById("mobileNumber").style.borderColor = "#ff0000" : "" == l || null == l ? document.getElementById("email").style.borderColor = "#ff0000" : $scope.nextOtppage() : (document.getElementById("userName").style.borderColor = "#ff0000", document.getElementById("mobileNumber").style.borderColor = "#ff0000", document.getElementById("email").style.borderColor = "#ff0000")
        }

    $scope.leadotpValidation = function () {

            var e = document.getElementById("leadformOtp").value;
            var errOtp = document.getElementById('erroOtp');
            
            if(getrandomNumber == e ){
                console.log('succ');
                errOtp.innerHTML = ' ';
                "" == e || null == e ? document.getElementById("leadformOtp").style.borderColor = "#ff0000" : ($("#leadFromOtp").modal("hide"), $("#leadFromNext").modal("show"))
                $scope.addNewLeadUser();
            } else {
                console.log('err');
                errOtp.innerHTML = 'Invalid OTP';
        
            }   
            
        }
}]).directive('onFinishLoader', function ($timeout) {
    return {
        restrict: 'A',
        link: function (scope, element, attr) {
            if (scope.$last === true) {
                $timeout(function () {
                    scope.$emit(attr.onFinishLoader);
                });
            }
        }
    }
});