//Header Controller

// angular.module('controllerModule')
app.controller('headerController', ['$scope', '$http', '$state', 'localStorageService', 'ngProgressFactory', 'Notification', 'mainAjax', '$ngConfirm', '$sce', function ($scope, $http, $state, localStorageService, ngProgressFactory, Notification, mainAjax, $ngConfirm, $sce) {

    // Hold the view untill the required data's are loaded
    $scope.pageReady = false;

    $scope.baseUrl = mainAjax.baseUrl;
   
    $scope.progressbar = ngProgressFactory.createInstance();
    $scope.progressbar.setColor('#0CE713');

    $scope.pageReady = true;


    $scope.$on('loadSwiper', function (ngRepeatFinishedEvent) {
        var swiper = new Swiper('.home-swipper-container', {
            slidesPerView: 4,
            spaceBetween: 30,
            navigation: {
                nextEl: '.home-bottom-next',
                prevEl: '.home-bottom-prev',
            },
            breakpoints: {
                767: {
                    slidesPerView: 2,
                    slidesPerColumn: 2
                }
            }
        });
    });

  

    //popup
    $scope.trustAsHtml = function (string) {
        console.log(string)
        return $sce.trustAsHtml(string);
    };

    function combineArray(firstArray, secondArray) {
        let formattedArray = []
        let resultCombineArray = []
        for (var i = 0; i < firstArray.length; i++) {
            let products = {
                name: firstArray[i].name,
                href: '/kotak/strategy/' + firstArray[i].name
            }
            formattedArray.push(products)
        }
        resultCombineArray = formattedArray.concat(secondArray)
        return resultCombineArray
    }


    $scope.showval = true;
    $scope.mainTitleCont = 'Overview';
    $scope.overview = 'Overview';
    $scope.makeStatic = function (name, id) {
        console.log("hiiiiiiiiiiiiiiiii")
        $scope.mainTitleCont = name;
        $scope[id] = name

    }

    // make request to get available product list
    $scope.loadProducts = function () {
        //try {
        let reqUrl = `${mainAjax.baseUrl}/cms/products`;
        let reqData = {
            method: 'GET',
            url: `${mainAjax.baseUrl}/cms/products`,
            headers: {
                'Content-Type': 'application / json'
            },
        };
        mainAjax.resolveRequestData(reqData).then(function (res) {
            // debugger
            // response.json().then(function (res) {
            //     debugger
            let lAryResultData = res.data.data;
            console.log(res.data.data)
            let lAryResponse = [];
            let lIntDisplaySize = 3;

            localStorageService.set('products', res.data.data);

            for (let products of Array.from(res.data.data)) {
                localStorageService.set(products.name, products.product_id)
            }

            $scope.availableProducts = res.data.data;
            $scope.availableProductsnew = res.data.data;
            $scope.resultCombineArray = combineArray($scope.availableProducts, $scope.searchElements)
            // $scope.$apply()
            if($scope.availableProducts && $scope.availableProducts.length>0 && document.getElementById('selesctproductnew')){
                $(document).ready(function () {
                    $('#selesctproductnew').SumoSelect();
                });
            }

            // let desc = '<p style="text-align: center;"><img alt="" src="https://www.kotakmf.com/assets/images/investor_services/NAV-02-01-01.svg" /><img alt="" src="https://www.kotakbusinesshub.com/assets/images/prelogin.png" style="height:113px; width:150px" /></p> <p>Dear Investor,</p><p>&nbsp;</p>  <p>Did you know you can now invest in PMS investment approaches directly?</p><p>&nbsp;</p> <p>To know more about investing directly and the investment approaches we offer, you can write to us at Kmamcpmsclient.servicinb@kotak.com</p><p>&nbsp;</p>   <p>We are happy to help you at all times!</p> <p>&nbsp;</p>  <p>Warm Regards,</p>   <p>&nbsp;</p>  <p>Kotak PMS,</p> ';
            // $scope.popup_desc  = desc;
            let getPopSession = sessionStorage.getItem('checksessionPopup');
            console.log(getPopSession)
            if(getPopSession == null){
                document.getElementById('popupopen').click();
            }
            

            // show the view when the required data's are loaded
            $scope.pageReady = true;
            // })
        }, function (error) {
            if (error.status != 401) {
                Notification.error({
                    title: 'Error',
                    message: 'Oops! something broken'
                });
            }

        });
        // } catch (e) {
        //     console.log(e)
        //     Notification.error({
        //         message: 'Oops! something broken'
        //     });
        // }
    };

    $scope.closeModal = function (){
        // let popup = document.getElementById('popup');
        // if(popup){
        //     popup.style.display = 'none';
        //     sessionStorage.setItem('checksessionPopup', true)
        // }

        $('#popup').modal('hide');
        sessionStorage.setItem('checksessionPopup', true)

    }
    $scope.leadForm = {};

    $scope.hideNumber = function () {
        if ($scope.leadForm.mobileNumber) {
            let number = $scope.leadForm.mobileNumber;
            let totalCharacter = $scope.leadForm.mobileNumber.length;
            let char = '';
            for (let i = 0; i < totalCharacter; i++) {
                char += '*'
            }
            char = char.substring(0, totalCharacter - 2) + number.charAt(totalCharacter - 2) + number.charAt(totalCharacter - 1);
            return char;

        }
    }

    function filterIt(arr, s) {
        var matches = [],
            i;
        for (i = arr.length; i--;) {
            if (arr[i]['name'].toLowerCase().indexOf(s.search.toLowerCase()) > -1) {
                matches.push(arr[i]);
            }
        }
        return matches
    }
    $(".closeSearch").hide();
    $scope.SearchByName = function (byName) {
        $("#search-container").modal("show");
        $(".closeSearch").show();
        let data = {
            "search": byName
        };
        $scope.resultArray = filterIt($scope.resultCombineArray, data)
    }

    $scope.aboutContent = function (contentName, id) {
        localStorage.setItem("pageContent", contentName);
        localStorage.setItem("pageId", id);
        if ($state.current.name == 'about') {
            $state.go($state.current, {}, {
                reload: true
            });
        } else {
            $state.go('about', {}, {
                reload: false
            });
        }
    }

    $scope.searchElements = [{
        name: 'Overview',
        href: '/kotak/about'
    }, {
        name: 'NRI Corner',
        href: '/kotak/nri'
    }, {
        name: 'Our Team',
        href: '/kotak/about'
    }, {
        name: 'Our Research Team',
        href: '/kotak/about'
    }, {
        name: 'Our Investment Process',
        href: '/kotak/about'
    }, {
        name: 'Investment Parameters',
        href: '/kotak/about'
    }, {
        name: 'Our Edge',
        href: '/kotak/about'
    }, {
        name: 'Market Outlook',
        href: '/kotak/marketoutlook'
    }, {
        name: 'FAQS',
        href: '/kotak/faqs'
    }, {
        name: 'Downloads ',
        href: '/kotak/downloads'
    }, {
        name: 'Customer Login',
        href: 'https://pms.kotakmutual.com/pms/'
    }, {
        name: 'Employee Login',
        href: 'https://www.kotakportfoliomanagement.com/'
    }, {
        name: 'Partner Login',
        href: 'https://www.kotakportfoliomanagement.com/AMCDetails/AMC_RM_Details.aspx?Flag=0'
    }, {
        name: 'About Us',
        href: '/kotak/about/1'
    }]


    $scope.leadFormValidationcommon = function () {

        var e = document.getElementById("userName").value,
            t = document.getElementById("mobileNumber").value,
            l = document.getElementById("email").value;
        "" != e && null != e || "" != t && null != t || "" != l && null != l ? "" == e || null == e ? document.getElementById("userName").style.borderColor = "#ff0000" : "" == t || null == t ? document.getElementById("mobileNumber").style.borderColor = "#ff0000" : "" == l || null == l ? document.getElementById("email").style.borderColor = "#ff0000" : $scope.nextOtppage() : (document.getElementById("userName").style.borderColor = "#ff0000", document.getElementById("mobileNumber").style.borderColor = "#ff0000", document.getElementById("email").style.borderColor = "#ff0000")
    }

    // Get available products on page load


    $scope.getRandomIntOTP = function () {

        let min = 1000;
        let max = 9999;
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }
    var getrandomNumber;
    
    $scope.nextOtppage = function () {

        getrandomNumber = $scope.getRandomIntOTP();
        var phoneNumber = document.getElementById("mobileNumber").value;
        //var typeProductVAl = document.getElementById('typeProductVAl').value;

   
            let details = {mobileNo: phoneNumber,  id:getrandomNumber }            
                try {
                    let reqDataq = {
                        url: `${mainAjax.baseUrl}/cms/sms6`,
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: toksd
                        },
                        data: {
                            details:btoa(JSON.stringify(details)),
                        }
                    };
                    mainAjax.resolveRequestData(reqDataq).then(function (res) {       
                        ($("#leadFrom").modal("hide"), $("#leadFromOtpleadFromcommon").modal("show"))
                    }, function (error) {
                        if (error.status != 401) {                           
                        }        
                    });        
                } catch (e) {
                    console.log('e', e);
                    if (e.status == 401) {
                        $state.go('login');
                    }
                }
            


        // $.ajax({
        //     url: uRLPath,
        //     type: 'POST',
        //     success: function (succ) {
        //     },
        //     error: function (err) {
        //     }
        // });
        // ($("#leadFrom").modal("hide"), $("#leadFromOtp").modal("show"))
    }
    var timerOn = true;
    
    function timer(remaining) {
        
      var m = Math.floor(remaining / 60);
      var s = remaining % 60;
      
      m = m < 10 ? '0' + m : m;
      s = s < 10 ? '0' + s : s;
      var getTimer = document.getElementById('timer');
        if(!getTimer){
            //
        } else{
      document.getElementById('timer').innerHTML = m + ':' + s;

        }
      remaining -= 1;
      
      if(remaining >= 0 && timerOn) {
        setTimeout(function() {
            timer(remaining);
        }, 1000);
        return;
      }
    
      if(!timerOn) {
        // Do validate stuff here
        window.onload = function() {
            window.setTimeout(setDisabled, 1000);
        }
        return;
      }
      
      // Do timeout stuff here
      setTimeout(function() {
        document.getElementById('resendEnable').disabled = false;
    }, 1000);
    }
    timer(60);
$scope.resendClick = function () {
        timer(60);
        setTimeout(function() {
            document.getElementById('resendEnable').disabled = true;
        }, 1000);
        $scope.nextOtppage();
        
    }
     
$scope.leadFormValidation = function () {

        var e = document.getElementById("userName").value,
            t = document.getElementById("mobileNumber").value,
            l = document.getElementById("email").value;
        "" != e && null != e || "" != t && null != t || "" != l && null != l ? "" == e || null == e ? document.getElementById("userName").style.borderColor = "#ff0000" : "" == t || null == t ? document.getElementById("mobileNumber").style.borderColor = "#ff0000" : "" == l || null == l ? document.getElementById("email").style.borderColor = "#ff0000" : $scope.nextOtppage() : (document.getElementById("userName").style.borderColor = "#ff0000", document.getElementById("mobileNumber").style.borderColor = "#ff0000", document.getElementById("email").style.borderColor = "#ff0000")
    }

$scope.leadotpValidation = function () {

        var e = document.getElementById("leadformOtp").value;
        var errOtp = document.getElementById('erroOtp');
        
        if(getrandomNumber == e ){
            console.log('succ');
            errOtp.innerHTML = ' ';
            "" == e || null == e ? document.getElementById("leadformOtp").style.borderColor = "#ff0000" : ($("#leadFromOtp").modal("hide"), $("#leadFromNextleadFromcommon").modal("show"))
            $scope.addNewLeadUser();
        } else {
            console.log('err');
            errOtp.innerHTML = 'Invalid OTP';
    
        }   
        
    }




}]).directive('onFinishRender', function ($timeout) {
    return {
        restrict: 'A',
        link: function (scope, element, attr) {
            if (scope.$last === true) {
                $timeout(function () {
                    scope.$emit(attr.onFinishRender);
                });
            }
        }
    }
});