const routing = angular.module('routing', ['ui.router']);
routing.config(function ($stateProvider, $urlRouterProvider, $locationProvider) {
  $urlRouterProvider.otherwise('/');
  $stateProvider
    .state('/', {
      url: "/",
      templateUrl: "./views/home.html",
      controller: "homeController"
    })
    /*.state('about', {
      url: "/kotak/about/:id",
      templateUrl: "./views/about.html",
      controller: "aboutusController"
    })*/
    .state('about', {
      url: "/kotak/about",
      templateUrl: "./views/about.html",
      controller: "aboutusController"
    })
    .state('nri', {
      url: "/kotak/nri",
      templateUrl: "./views/nri.html",
      controller: "nriController"
    }).state('calculator', {
      url: "/kotak/calculator",
      templateUrl: "./views/calculator.html",
      controller: "calculatorController"
    }).state('faqs', {
      url: "/kotak/faqs",
      templateUrl: "./views/faqs.html",
      controller: "faqController"
    }).state('downloads', {
      url: "/kotak/downloads",
      templateUrl: "./views/downloads.html",
      controller: "downloadsController"
    }).state('bulkUpload', {
      url: "/kotak/bulkCalculator",
      templateUrl: "./views/bulkUpload.html",
      controller: "bulkcalculatorController"
    }).state('manualUpload', {
      url: "/kotak/manualCalculator",
      templateUrl: "./views/manualUpload.html",
      controller: "manualcalculatorController"
    })
    .state('strategy', {
      url: "/kotak/strategy/:productName",
      templateUrl: "./views/strategyOne.html",
      controller: "strategiesController"
    })
    .state('otpSubmit', {
      url: "/kotak/otpSubmit",
      templateUrl: "./views/login-otp.html",
      // controller: "strategiesController"
    })

    .state('marketOutlook', {
      url: "/kotak/marketoutlook",
      templateUrl: "./views/marketOutlook.html",
      controller: "marketController"
    }).state('employeeLogin', {
      url: "/kotak/employeeLogin",
      templateUrl: "./views/login.html",
      // controller: "strategiesController"
    }).state('partnerLogin', {
      url: "/kotak/partnerLogin",
      templateUrl: "./views/partnerLogin.html",
      controller: "partnerController"
    })
    .state('postLogin', {
      url: "/kotak/postLogin",
      templateUrl: "./views/postLogin.html",
      // controller: "strategiesController"
    })

  $locationProvider.html5Mode({
    enabled: true,
    requireBase: true
  });
  $locationProvider.hashPrefix('!');
});