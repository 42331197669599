//Strategies Controller

// angular.module('controllerModule')
app.controller('strategiesController', ['$scope', '$http', '$state', 'localStorageService', 'ngProgressFactory', 'Notification', 'mainAjax', '$ngConfirm', 'anchorSmoothScroll', '$location', '$stateParams', '$sce', '$window', function ($scope, $http, $state, localStorageService, ngProgressFactory, Notification, mainAjax, $ngConfirm, anchorSmoothScroll, $location, $stateParams, $sce, $window) {


    $scope.whatsAppWindow = $window.screen.width;
    $scope.sharePage = $location.absUrl().split('?')[0]
    // Hold the view untill the required data's are loaded
    $scope.pageReady = false;

    $scope.baseUrl = mainAjax.baseUrl;

    $scope.strategyCont = true;
    // get products saved from home page
    $scope.availableProducts = localStorageService.get('products');

    // progress bar configuration
    $scope.pageLoading = false;
    $scope.progressbar = ngProgressFactory.createInstance();
    $scope.progressbar.setColor('#0CE713');
    $scope.productInfo = [];


    //get small and mid strategy

    let reqUrl = `${mainAjax.baseUrl}/cms/products`;
    let reqData = {
        method: 'GET',
        url: `${mainAjax.baseUrl}/cms/products`,
        headers: {
            'Content-Type': 'application / json'
        },
    };
    let lAryResultData
    mainAjax.resolveRequestData(reqData).then(function (res) {

        lAryResultData = res.data.data;
        //for pdf file view

    }, function (error) {
        if (error.status != 401) {
            Notification.error({
                title: 'Error',
                message: 'Oops! something broken'
            });
        }

    });
    //end small and mid
    $scope.loadPdfFilaaae = function () {
        console.log('a');
        var page;
        var url = 'https://s3.ap-south-1.amazonaws.com/kotakpms/120';
        var thePdf = null;
        var scale = 1;
        var count = 1;
        pdfjsLib.disableWorker = true;
        pdfjsLib.GlobalWorkerOptions.workerSrc = '../js/pdf.worker.js';
        pdfjsLib.getDocument(url).then(function getPdfHelloWorld(pdf) {
            thePdf = pdf;
            var viewer = document.getElementById('pdf-viewer');
            for (page = 1; page <= pdf.numPages; page++) {
                var innerDiv = document.createElement("div");
                innerDiv.id = page;
                innerDiv.class = "page";
                var canvas = document.createElement("canvas");
                canvas.className = 'pdf-page-canvas';
                innerDiv.appendChild(canvas);
                //innerDiv.innerHTML = " Page + " + page;
                viewer.appendChild(innerDiv);
                //callTurn();
                renderPage(page, canvas);
            }
        });

        function renderPage(pageNumber, canvas) {
            thePdf.getPage(pageNumber).then(function (page) {
                viewport = page.getViewport(scale);
                canvas.height = viewport.height;
                canvas.width = $(window).width();
                page.render({ canvasContext: canvas.getContext('2d'), viewport: viewport });
                console.log("Rendering... ");
                if (count == thePdf.numPages) {
                    console.log("Count value : " + count);
                    var innerDiv = document.createElement("div");
                    innerDiv.class = "hard";
                    document.getElementById('pdf-viewer').appendChild(innerDiv);
                    document.getElementById('pdf-viewer').appendChild(innerDiv);
                    callTurn();
                }
                count += 1;
            });
        }

        function callTurn() {
            console.log("Ready");

            $('#pdf-viewer').turn({
                display: 'double',
                acceleration: false,
                width: 1200,
                height: 850,
                elevation: 100,
                peel: true
            });
            $(window).bind('keydown', function (e) {
                if (e.keyCode == 37)
                    $('#pdf-viewer').turn('previous');
                else if (e.keyCode == 39)
                    $('#pdf-viewer').turn('next');
            });
        }
    }

    
    $scope.getFileByProductId = function (productId) {
        $scope.multiDocFile = '';
        try {
         
            let reqData = {
                method: 'GET',
                url: `${mainAjax.baseUrl}/cms/getmultiplefiledetails?fileid=${productId}`,
                headers: {
                    'Content-Type': 'application / json'
                },
            };
            mainAjax.resolveRequestData(reqData).then(function (result) {
                $scope.progressbar.complete();
                console.log(result)
                console.log(result.data.data);
                let newarray = [];
                let localarr = result.data.data;
                
                for(let i=0;i<localarr.length;i++){
                    let url = localarr[i].fileurl;
                    console.log(url)
                    localarr[i].fileName = url.split('/').pop();
                    newarray.push(localarr[i])
                    //  
                }
                $scope.multiDocFile = newarray;
                if($scope.multiDocFile.length>0){
                    setTimeout(function(){
                        document.getElementById('checkanddisplay').style.display = 'block';
                    },1000);
                }
               
            }, function (error) {
                Notification.error({
                    message: 'Oops! something broken'
                });
            });
        } catch (e) {
            Notification.error({
                message: 'Oops! something broken'
            });
        }

    }
    //Load selected super headers header data and description data
    let isPdfLOcationUrl;
    $scope.makeDynamic = function (product, index, swipeAction) {
        console.log(product);

        angular.forEach(lAryResultData, function (pdfData) {
            //  console.log(product.product_id);
            //  console.log(pdfData.product_id);
            if (product.product_id === pdfData.product_id) {
                //    console.log('135');
                if (pdfData.is_pdf === null || pdfData.pdf_location === '' || pdfData.is_pdf === 0) {
                    //if(pdfData.is_pdf === null){
                    // console.log('137');
                    $scope.pdfPreview = pdfData;
                    $scope.pdfPreview.is_pdf = null;
                    if (!!product) {
                        if (index !== undefined) {
                            let indexVal = (swipeAction === 'left') ? index + 1 : index - 1;
                            if (product[indexVal]) {
                                $scope.SelectedProductName = product[indexVal].name;
                                $scope.productHeaders = product[indexVal];
                                $scope.mainTitleCont = product[indexVal].name;
                                $('.' + product[indexVal].super_header_id).click();
                            } else {
                                $scope.SelectedProductName = product.name;
                                $scope.productHeaders = product;
                                $scope.mainTitleCont = product.name;
                            }
                        } else {
                            $scope.SelectedProductName = product.name;
                            $scope.productHeaders = product;
                            $scope.mainTitleCont = product.name;
                        }
                    }

                } else {
                    $scope.pdfPreview = pdfData;
                    $scope.pdfPreview.is_pdf = 1;
                    isPdfLOcationUrl = pdfData.pdf_location;
                    loadPdfFile(pdfData.pdf_location);
                }
            } else {
                if (!!product) {
                    if (index !== undefined) {
                        let indexVal = (swipeAction === 'left') ? index + 1 : index - 1;
                        if (product[indexVal]) {
                            $scope.SelectedProductName = product[indexVal].name;
                            $scope.productHeaders = product[indexVal];
                            $scope.mainTitleCont = product[indexVal].name;
                            console.log('.' + product[indexVal].super_header_id);
                            $('.' + product[indexVal].super_header_id).click();
                        } else {
                            $scope.SelectedProductName = product.name;
                            $scope.productHeaders = product;
                            $scope.mainTitleCont = product.name;
                        }
                    } else {
                        $scope.SelectedProductName = product.name;
                        $scope.productHeaders = product;
                        $scope.mainTitleCont = product.name;
                    }
                }
            }

        });

    }

    //Display raw html to page view
    $scope.trustAsHtml = function (string) {
        return $sce.trustAsHtml(string);
    };

    $scope.$on('loadStrategySwiper', function (ngRepeatFinishedEvent) {

        var swiper = new Swiper('.strategies-mobile-nav-container', {
            slidesPerView: '1',
            autoHeight: true,
            spaceBetween: 30,
            navigation: {
                nextEl: '.strategies-mobile-bottom-next',
                prevEl: '.strategies-mobile-bottom-prev',
            },
        });
    });

    let gProductData = [];
    let fetchProductData = function (strategy) {
        try {
            let reqUrl = `${mainAjax.baseUrl}/cms/superheaders?productId=${strategy}`;
            let reqData = {
                method: 'GET',
                url: `${mainAjax.baseUrl}/cms/superheaders?productId=${strategy}`,
                headers: {
                    'Content-Type': 'application / json'
                },
            };
            mainAjax.resolveRequestData(reqData).then(function (result) {
                $scope.progressbar.complete();
                gProductData = result.data;
                let data = result.data.data;
                $scope.productInfo = data;

                $scope.productName = (!!result.product && result.product[0]) ? result.product[0].name : '';
                if (!!data && data[0]) {
                    $scope.makeDynamic(data[0]);
                    $scope.getFileByProductId(data[0].product_id);
                }
                // show the view when the required data's are loaded

                // load first product info
                if ($scope.productInfo[0] !== undefined) {
                    $scope.pageReady = true;
                } else {
                    Notification.error({
                        message: 'Strategy not found, choose a different strategy'
                    });
                }
            }, function (error) {
                Notification.error({
                    message: 'Oops! something broken'
                });
            });
        } catch (e) {
            Notification.error({
                message: 'Oops! something broken'
            });
        }
    };
    let checkprodid = setInterval(() => {

        let lStrURLPath = $stateParams.productName;
        $scope.productFromMenu = lStrURLPath
        let productId = localStorageService.get(lStrURLPath);

        if (productId) {
            fetchProductData(productId);
            clearInterval(checkprodid)
        }
    }, 1000);


    $scope.leadForm = {};

    $scope.hideNumber = () => {
        if ($scope.leadForm.mobileNumber) {
            let number = $scope.leadForm.mobileNumber;
            let totalCharacter = $scope.leadForm.mobileNumber.length;
            let char = '';
            for (let i = 0; i < totalCharacter; i++) {
                char += '*'
            }
            char = char.substring(0, totalCharacter - 2) + number.charAt(totalCharacter - 2) + number.charAt(totalCharacter - 1);
            return char;
        }
    }
    // Download dynamic PDF Creation
    $scope.downloadPDF = function () {
        var filePath = 0;
        let reqData = {
            url: `${mainAjax.baseUrlLocal}/download/download`,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            data: gProductData
        };
        mainAjax.downloadRequest(reqData).then(function (result) {
            console.log(result)
            $scope.fileURL = result.data.data
            console.log($scope.fileURL)
            var a = document.getElementById("downloadLink");
            a.href = `${mainAjax.baseUrlLocal}/download/downloadFile?filePath=` + $scope.fileURL;
            a.click();
        }, function (error) {
            Notification.error({
                message: 'Oops! something broken'
            });
        });

    }
    $scope.download = function (urlFile) {
        let reqData = {
            url: `${mainAjax.baseUrlLocal}/download/downloadFile`,
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
            params: {
                "filePath": urlFile
            }

        };
        mainAjax.downloadFile(reqData).then(function () {

        })
    }
    $scope.addNewLeadUser = async () => {
        await getLoginToken();
        var name = document.getElementById("userName").value;
        var mobile = document.getElementById("mobileNumber").value;
        var email = document.getElementById("email").value;
        var dataselect = document.getElementById("selesctproduct").value;
        let lObjNewLeadUser = {
            'name': name,
            'phonenumber': mobile,
            'email': email,
            'product1': $scope.selecteditems[0] != undefined ? $scope.selecteditems[0] : null,
            'product2': $scope.selecteditems[1] != undefined ? $scope.selecteditems[1] : null,
            'product3': $scope.selecteditems[2] != undefined ? $scope.selecteditems[2] : null,
            'product4': $scope.selecteditems[3] != undefined ? $scope.selecteditems[3] : null,
            'product5': $scope.selecteditems[4] != undefined ? $scope.selecteditems[4] : null,
            'product6': $scope.selecteditems[5] != undefined ? $scope.selecteditems[5] : null,
            'product7': $scope.selecteditems[6] != undefined ? $scope.selecteditems[6] : null
        }
        console.log(lObjNewLeadUser);

        //let sessionData = localStorage.getItem('kspswk_aa');

        let reqData = {
            url: `${mainAjax.baseUrl}/product/createLead`,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: toksd//"Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MjE1LCJsb2dnZWRJbiI6IjE1NDI5NzU5MjAwMzciLCJpYXQiOjE1NDI5NzU5MjB9.yX9C42Gz1aRNDr1QYyKCG1HpXtCa0vHP7mOd5uaHr-w"
            },
            data: lObjNewLeadUser
        };
        console.log(`${mainAjax.baseUrl}/product/createLead`);
        mainAjax.resolveRequestData(reqData).then(function (result) {
            console.log(result);

        }, function (error) {
            Notification.error({
                message: 'Oops! something broken'
            });
        });

    }

    $scope.getRandomIntOTP = function () {

        let min = 1000;
        let max = 9999;
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }
    var getrandomNumber;

    $scope.nextOtppage = function () {

        getrandomNumber = $scope.getRandomIntOTP();
        var phoneNumber = document.getElementById("mobileNumber").value;
        //var typeProductVAl = document.getElementById('typeProductVAl').value;


        //var uRLPath = "http://sms6.routesms.com:8080/bulksms/bulksms?username=ktamc&password=mtMZwh0C&type=0&dlr=1&source=KOTKMF&destination="+phoneNumber+"&&message=Welcome to Kotak. The OTP is:" +getrandomNumber;
        var uRLPath = "https://sms6.routesms.com:8443/bulksms/bulksms?username=ktamc&password=mtMZwh0C&type=0&dlr=1&source=KOTKMF&destination=" + phoneNumber + "&&message=Welcome to Kotak. The OTP is:" + getrandomNumber;


        $.ajax({
            url: uRLPath,
            type: 'POST',
            success: function (succ) {
            },
            error: function (err) {
            }
        });
        ($("#leadFrom").modal("hide"), $("#leadFromOtp").modal("show"))
    }
    var timerOn = true;

    function timer(remaining) {

        var m = Math.floor(remaining / 60);
        var s = remaining % 60;

        m = m < 10 ? '0' + m : m;
        s = s < 10 ? '0' + s : s;
        var getTimer = document.getElementById('timer');
        if (!getTimer) {
            //
        } else {
            document.getElementById('timer').innerHTML = m + ':' + s;

        }
        remaining -= 1;

        if (remaining >= 0 && timerOn) {
            setTimeout(function () {
                timer(remaining);
            }, 1000);
            return;
        }

        if (!timerOn) {
            // Do validate stuff here
            window.onload = function () {
                window.setTimeout(setDisabled, 1000);
            }
            return;
        }

        // Do timeout stuff here
        setTimeout(function () {
            document.getElementById('resendEnable').disabled = false;
        }, 1000);
    }
    timer(60);
    $scope.resendClick = function () {
        console.log("data resend");
        timer(60);
        setTimeout(function () {
            document.getElementById('resendEnable').disabled = true;
        }, 1000);
        $scope.nextOtppage();

    }

    $scope.leadFormValidation = function () {

        var e = document.getElementById("userName").value,
            t = document.getElementById("mobileNumber").value,
            l = document.getElementById("email").value;
        "" != e && null != e || "" != t && null != t || "" != l && null != l ? "" == e || null == e ? document.getElementById("userName").style.borderColor = "#ff0000" : "" == t || null == t ? document.getElementById("mobileNumber").style.borderColor = "#ff0000" : "" == l || null == l ? document.getElementById("email").style.borderColor = "#ff0000" : $scope.nextOtppage() : (document.getElementById("userName").style.borderColor = "#ff0000", document.getElementById("mobileNumber").style.borderColor = "#ff0000", document.getElementById("email").style.borderColor = "#ff0000")
    }

    $scope.leadotpValidation = function () {

        var e = document.getElementById("leadformOtp").value;
        var errOtp = document.getElementById('erroOtp');

        if (getrandomNumber == e) {
            console.log('succ');
            errOtp.innerHTML = ' ';
            "" == e || null == e ? document.getElementById("leadformOtp").style.borderColor = "#ff0000" : ($("#leadFromOtp").modal("hide"), $("#leadFromNext").modal("show"))
            $scope.addNewLeadUser();
        } else {
            console.log('err');
            errOtp.innerHTML = 'Invalid OTP';

        }

    }

    $scope.downloadPDFIfnotNull = () => {
        console.log(isPdfLOcationUrl);
        let appendPdfend = isPdfLOcationUrl;
        console.log(appendPdfend);

        // var a = document.getElementById("downloadLinkPdf");
        //     //console.log(`${mainAjax.baseUrl}/download/downloadFile?filePath=` + $scope.fileURL);
        //     a.href = appendPdfend;
        //     a.download = 'file.pdf';
        //     a.click();

        // var link = document.createElement('a');
        // link.href = appendPdfend;
        // link.download = 'file.pdf';
        // link.dispatchEvent(new MouseEvent('click'));

        //             var file_path = appendPdfend;
        // var a = document.createElement('A');
        // a.href = file_path;
        // a.download = file_path.substr(file_path.lastIndexOf('/') + 1);
        // document.body.appendChild(a);
        // a.click();

        var url = appendPdfend;
        window.open(url, 'Download');

    }


    var elem = document.getElementById('fullscreenDiv');
    //function openFullscreen() {
    $scope.openFullscreen = function () {


        var el = document.getElementById('element');
        el.webkitRequestFullscreen();
        //    var element = document.getElementById("fullscreenDiv");
        //    element.classList.add("fullscreen_div");


        //    var openDiv = document.getElementById("openDiv");
        //    openDiv.style.display = 'none';

        //    var closeDiv = document.getElementById("closeDiv");
        //    closeDiv.style.display = 'block';
        //   if (elem.requestFullscreen) {
        //     elem.requestFullscreen();
        //   } else if (elem.mozRequestFullScreen) { /* Firefox */
        //     elem.mozRequestFullScreen();
        //   } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
        //     elem.webkitRequestFullscreen();
        //   } else if (elem.msRequestFullscreen) { /* IE/Edge */
        //     elem.msRequestFullscreen();
        //   }
    }

    var elem = document.getElementById('fullscreenDiv');
    //function openFullscreen() {
    $scope.openFullscreen = function () {

        var el = document.getElementById('element');
        el.classList.add("finalFullScreen");
        // el.webkitRequestFullscreen();
        var openDiv = document.getElementById("openDiv");
        openDiv.style.display = 'none';

        var closeDiv = document.getElementById("closeDiv");
        closeDiv.style.display = 'block';

        var fullScreenDiveChanges = document.getElementById('setFontOnmiddleOnfullscreen');
        if (fullScreenDiveChanges) {
            fullScreenDiveChanges.classList.remove("setFontOnmiddlebeforefullscreen");
            fullScreenDiveChanges.classList.add("setFontOnmiddleOnfullscreen");
        }


        var setCenteronfullscreen = document.getElementById('setpdfleftbefore');
        if (setCenteronfullscreen) {
            setCenteronfullscreen.classList.remove("setpdfleftbefore");
            setCenteronfullscreen.classList.add("setCenteronfullscreen");
        }


    }

    //function closeFullscreen() {
    $scope.closeFullscreen = function () {
        var el = document.getElementById('element');
        el.classList.remove("finalFullScreen");
        // el.webkitRequestFullscreen();

        var openDiv = document.getElementById("openDiv");
        openDiv.style.display = 'block';

        var closeDiv = document.getElementById("closeDiv");
        closeDiv.style.display = 'none';

        var fullScreenDiveChanges = document.getElementById('setFontOnmiddleOnfullscreen');
        if (fullScreenDiveChanges) {
            fullScreenDiveChanges.classList.remove("setFontOnmiddleOnfullscreen");
            fullScreenDiveChanges.classList.add("setFontOnmiddlebeforefullscreen");
        }



        var setCenteronfullscreen = document.getElementById('setpdfleftbefore');
        if (setCenteronfullscreen) {
            setCenteronfullscreen.classList.remove("setpdfleftbefore");
            setCenteronfullscreen.classList.add("setCenteronfullscreen");
        }

    }

    //bnew
       // $scope.SelectedProductName = 'multipleFile';
       $scope.openfilterfilesucits = function (string) {
         console.log($scope.multiDocFile)
        $scope.SelectedProductName = 'documents'
      //  $scope.mainTitleCont = "Files";
    };
    
    $scope.openfilterfilesucits_back = function (string) {
        if(document.getElementById('down')){
          var doww = document.getElementById('down');
        doww.classList.add("btnMobileColor");
        doww.classList.remove("btnMobilelight");
        }
        if(document.getElementById('gdDoc')){
        var btnGD = document.getElementById('gdDoc');
        btnGD.classList.add("btnMobilelight");
        btnGD.classList.remove("btnMobileColor");
        }
        $scope.SelectedProductName = 'multipleFile_back';
        $scope.shareClass = false;
      //  $scope.mainTitleCont = "Files";
    };

    var widthScreen = window.screen.width;  // 1280
    console.log(widthScreen);
    if(widthScreen <720){
        $scope.openfilterfilesucits_back();
    }
    $scope.onlymobileClick = false;  


}]).directive('onFinishLoader', function ($timeout) {
    return {
        restrict: 'A',
        link: function (scope, element, attr) {
            if (scope.$last === true) {
                $timeout(function () {
                    scope.$emit(attr.onFinishLoader);
                });
            }
        }
    }
});