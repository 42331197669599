angular.module('mainService', [])

    .factory('mainAjax', ['$http', function ($http) {
        return {

            // baseUrl: 'https://admin.kotakportfoliomanagementservices.com',
            // baseUrlLocal: 'https://admin.kotakportfoliomanagementservices.com',

            // baseUrl: 'http://localhost:2002',
            // baseUrlLocal: 'http://localhost:2002',

            // baseUrl: 'http://admintest.kotakpms.com',
            // baseUrlLocal: 'http://admintest.kotakpms.com',

            baseUrl: 'https://admin.kotakpms.com',
            baseUrlLocal: 'https://admin.kotakpms.com',
            
            //baseUrlLocal: 'http://192.168.10.130:4001',
            //baseUrlLocal: 'http://192.168.10.199:2002',
            resolveRequest: function (url, reqData) {
                return fetch(url, reqData);
            },
            downloadRequest: function (reqData) {
                return $http(reqData);
            },
            downloadFile: function (reqData) {
                return $http(reqData);
            },
            resolveRequestData: function (reqData) {
                return $http(reqData);
            }
        }
    }]);