const controllerModule = angular.module('controllerModule', []);


//Main Controller


app.controller('mainController', ['$scope', '$http', '$state', 'localStorageService', 'ngProgressFactory', 'Notification', 'mainAjax', '$ngConfirm','angularUtils.directives.dirPagination', function ($scope, $http, $state, localStorageService, ngProgressFactory, Notification, mainAjax, $ngConfirm) {

    // progress bar configuration

    $scope.leadForm = {};

    $scope.hideNumber = () => {
        if ($scope.leadForm.mobileNumber) {
            let number = $scope.leadForm.mobileNumber;
            let totalCharacter = $scope.leadForm.mobileNumber.length;
            let char = '';
            for (let i = 0; i < totalCharacter; i++) {
                char += '*'
            }
            char = char.substring(0, totalCharacter - 2) + number.charAt(totalCharacter - 2) + number.charAt(totalCharacter - 1);
            return char;

        }
    }

}]);