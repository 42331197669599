//Admin Company Controller

// angular.module('controllerModule')
app.controller('aboutusController', ['$scope', '$timeout', '$http', '$state', 'localStorageService', 'ngProgressFactory', 'Notification', 'mainAjax', '$ngConfirm', 'anchorSmoothScroll', '$location', '$stateParams', function ($scope, $timeout, $http, $state, localStorageService, ngProgressFactory, Notification, mainAjax, $ngConfirm, anchorSmoothScroll, $location, $stateParams) {

    // our team members data section begins
    $scope.teamMembersCount = 3;

    // function to scroll to div block

    // get products saved from home page
    $scope.availableProducts = localStorageService.get('products');

    $scope.aboutActive = true;

    $scope.gotoAnchor = function (x) {
        var newHash = 'anchor' + x;
        if ($location.hash() !== newHash) {

            $location.hash('anchor' + x);

            anchorSmoothScroll.scrollTo('anchor' + x);
        } else {

            $anchorScroll();
        }
    };
    // $timeout(function () {
    //     $scope.mainTitleCont = 'overview';
    //     $scope.overview = 'overview';
    // }, 500);

    console.log($scope.mainTitleCont)

    console.log($scope.availableProducts)
    if($scope.availableProducts && $scope.availableProducts.length>0 && document.getElementById('selesctproduct')){
        $(document).ready(function () {
            console.log($('#selesctproduct').SumoSelect())
              $('#selesctproduct').SumoSelect();
            });
    }
    switch (Number($stateParams.id)) {
        case 2:
            $scope.gotoAnchor("ourTeam");
            break;
        case 3:
            $scope.gotoAnchor("ourResearch")
            break;
        case 4:
            $scope.gotoAnchor("InvestmentProcess");
            break;
        case 5:
            $scope.gotoAnchor("InvestmentParameter");
            break;
        case 6:
            $scope.gotoAnchor("ourEdge");
            break;
        default:

    }

    $scope.showval = true;

    $scope.makeStaticui = function (name, id) {
        makeStatic(name,id)
    }
    function makeStatic(name, id)  {
        console.log(name)
        console.log(id)
        var contentName = name;
        localStorage.setItem("pageContent", contentName);
        localStorage.setItem("pageId", id);
        $scope.mainTitleCont = id;
        $scope[id] = name;


        if (contentName == 'overview' || contentName == 'Overview') {

            $state.go('overview', {}, {
                reload: false
            });
            getUiupdate(name);
        } else if (contentName == 'The Team') {
            document.getElementById('aboutusId').style.display = 'none';
            document.getElementById('aboutusIdLoader').style.display = 'flex';

            loadTeamDetails('ourteam');

            setTimeout(function () {
                getUiupdate('The Team');
            }, 500);


            setTimeout(function () {
                document.getElementById('aboutusId').style.display = 'flex';
                document.getElementById('aboutusIdLoader').style.display = 'none';

            }, 1500);

            $state.go('ourteam', {}, {
                reload: false
            });
        } else if (contentName == 'Our Research Team') {

            document.getElementById('aboutusIdGroup').style.display = 'none';
            document.getElementById('aboutusIdGroupLoader').style.display = 'flex';

            

            setTimeout(function () {
                loadTeamDetailsreas('researchteam');
                getUiupdate('Our Research Team');
                setTimeout(function () {
                    document.getElementById('aboutusIdGroup').style.display = 'flex';
                    document.getElementById('aboutusIdGroupLoader').style.display = 'none';

                }, 1500);
            }, 500);



            $state.go('groupcompanies', {}, {
                reload: false
            });

        } else if (contentName == 'Our Investment Process') {
            $state.go('investmentprocess', {}, {
                reload: false
            });
            getUiupdate(name);
        } else if (contentName == 'Investment Parameters') {
            $state.go('parameters', {}, {
                reload: false
            });
            getUiupdate(name);
        } else if (contentName == 'Awards') {
            $state.go('awards', {}, {
                reload: false
            });
            getUiupdate(name);
        }



    }

    $scope.expandInfo = (index) => {
        if ($scope.ourTeamMembers[index].details !== null) {
            $scope.ourTeamMembers[index].fullDetails = $scope.ourTeamMembers[index].details;
            $scope.ourTeamMembers[index].details = null;
        } else {
            $scope.ourTeamMembers[index].details = $scope.ourTeamMembers[index].fullDetails;
            $scope.ourTeamMembers[index].fullDetails = null;
        }
    }

    // our team members data section end

    $scope.leadForm = {};
    $scope.hideNumber = () => {
        if ($scope.leadForm.mobileNumber) {
            let number = $scope.leadForm.mobileNumber;
            let totalCharacter = $scope.leadForm.mobileNumber.length;
            let char = '';
            for (let i = 0; i < totalCharacter; i++) {
                char += '*'
            }
            char = char.substring(0, totalCharacter - 2) + number.charAt(totalCharacter - 2) + number.charAt(totalCharacter - 1);
            return char;
        }
    };

    $scope.mainTitle = localStorage.getItem("pageContent");
    $scope.contentId = localStorage.getItem("pageId");
    // $scope.makeStatic($scope.mainTitle, $scope.contentId);
    makeStatic($scope.mainTitle, $scope.contentId);

    $scope.onchangeTest = function (that) {
        $scope.selecteditems = that;
        //  console.log($scope.product.name);
    }
    $scope.onchangeProduct = function () {
        $scope.currentCatValue = productData;
        $scope.categoryValues = categoryValues;
        console.log($scope.currentCatValue);
        console.log($scope.categoryValues);
    }
    var toksd;
    async function getLoginToken() {
        // var loginData = {
        //     "email": "senthilnathan.k@kotak.com",
        //     "password": "Abnegation@123",
        //     "is_admin": 1
        // };
        var loginData = {
            email: "eyJlbWFpbCI6InNlbnRoaWxuYXRoYW4ua0Brb3Rhay5jb20iLCJwYXNzd29yZCI6IkFibmVnYXRpb25AMTIzIn0="
        }
        let reqData = {
            url: `${mainAjax.baseUrl}/login`,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            data: loginData
        };
        await mainAjax.resolveRequestData(reqData).then(function (result) {
            console.log(result);
            toksd = result.data.data.token
            console.log("This is the token : " + toksd);
            return toksd;
        }, function (error) {
            // Notification.error({
            //     message: 'Oops! something broken'
            // });
        });
    }


    $scope.addNewLeadUser = async () => {
        await getLoginToken();
        var name = document.getElementById("userName").value;
        var mobile = document.getElementById("mobileNumber").value;
        var email = document.getElementById("email").value;
        var dataselect = document.getElementById("selesctproduct").value;
        let lObjNewLeadUser = {
            'name': name,
            'phonenumber': mobile,
            'email': email,
            'product1': $scope.selecteditems[0] != undefined ? $scope.selecteditems[0] : null,
            'product2': $scope.selecteditems[1] != undefined ? $scope.selecteditems[1] : null,
            'product3': $scope.selecteditems[2] != undefined ? $scope.selecteditems[2] : null,
            'product4': $scope.selecteditems[3] != undefined ? $scope.selecteditems[3] : null,
            'product5': $scope.selecteditems[4] != undefined ? $scope.selecteditems[4] : null,
            'product6': $scope.selecteditems[5] != undefined ? $scope.selecteditems[5] : null,
            'product7': $scope.selecteditems[6] != undefined ? $scope.selecteditems[6] : null
        }
        console.log(lObjNewLeadUser);

        //let sessionData = localStorage.getItem('kspswk_aa');

        let reqData = {
            url: `${mainAjax.baseUrl}/product/createLead`,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: toksd//"Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MjE1LCJsb2dnZWRJbiI6IjE1NDI5NzU5MjAwMzciLCJpYXQiOjE1NDI5NzU5MjB9.yX9C42Gz1aRNDr1QYyKCG1HpXtCa0vHP7mOd5uaHr-w"
            },
            data: lObjNewLeadUser
        };
        console.log(`${mainAjax.baseUrl}/product/createLead`);
        mainAjax.resolveRequestData(reqData).then(function (result) {
            console.log(result);

        }, function (error) {
            Notification.error({
                message: 'Oops! something broken'
            });
        });

    }

    $scope.getRandomIntOTP = function () {

        let min = 1000;
        let max = 9999;
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }
    var getrandomNumber;

    $scope.nextOtppage = function () {

        getrandomNumber = $scope.getRandomIntOTP();
        var phoneNumber = document.getElementById("mobileNumber").value;
        //var typeProductVAl = document.getElementById('typeProductVAl').value;

   
            let details = {mobileNo: phoneNumber,  id:getrandomNumber }            
                try {
                    let reqDataq = {
                        url: `${mainAjax.baseUrl}/cms/sms6`,
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: toksd
                        },
                        data: {
                            details:btoa(JSON.stringify(details)),
                        }
                    };
                    mainAjax.resolveRequestData(reqDataq).then(function (res) {       
                        ($("#leadFrom").modal("hide"), $("#leadFromOtp").modal("show"))
                    }, function (error) {
                        if (error.status != 401) {                           
                        }        
                    });        
                } catch (e) {
                    console.log('e', e);
                    if (e.status == 401) {
                        $state.go('login');
                    }
                }
            


        // $.ajax({
        //     url: uRLPath,
        //     type: 'POST',
        //     success: function (succ) {
        //     },
        //     error: function (err) {
        //     }
        // });
        // ($("#leadFrom").modal("hide"), $("#leadFromOtp").modal("show"))
    }


    const encodeBase64 = (data) => {
        return Buffer.from(data).toString('base64');
      }
      const decodeBase64 = (data) => {
        return Buffer.from(data, 'base64').toString('ascii');
      }


    var timerOn = true;

    function timer(remaining) {

        var m = Math.floor(remaining / 60);
        var s = remaining % 60;

        m = m < 10 ? '0' + m : m;
        s = s < 10 ? '0' + s : s;
        var getTimer = document.getElementById('timer');
        if (!getTimer) {
            //
        } else {
            document.getElementById('timer').innerHTML = m + ':' + s;

        }
        remaining -= 1;

        if (remaining >= 0 && timerOn) {
            setTimeout(function () {
                timer(remaining);
            }, 1000);
            return;
        }

        if (!timerOn) {
            // Do validate stuff here
            window.onload = function () {
                window.setTimeout(setDisabled, 1000);
            }
            return;
        }

        // Do timeout stuff here
        setTimeout(function () {
            document.getElementById('resendEnable').disabled = false;
        }, 1000);
    }
    timer(60);
    $scope.resendClick = function () {
        console.log("data resend");
        timer(60);
        setTimeout(function () {
            document.getElementById('resendEnable').disabled = true;
        }, 1000);
        $scope.nextOtppage();

    }

    $scope.leadFormValidation = function () {

        var e = document.getElementById("userName").value,
            t = document.getElementById("mobileNumber").value,
            l = document.getElementById("email").value;
        "" != e && null != e || "" != t && null != t || "" != l && null != l ? "" == e || null == e ? document.getElementById("userName").style.borderColor = "#ff0000" : "" == t || null == t ? document.getElementById("mobileNumber").style.borderColor = "#ff0000" : "" == l || null == l ? document.getElementById("email").style.borderColor = "#ff0000" : $scope.nextOtppage() : (document.getElementById("userName").style.borderColor = "#ff0000", document.getElementById("mobileNumber").style.borderColor = "#ff0000", document.getElementById("email").style.borderColor = "#ff0000")
    }

    $scope.leadotpValidation = function () {

        var e = document.getElementById("leadformOtp").value;
        var errOtp = document.getElementById('erroOtp');

        if (getrandomNumber == e) {
            console.log('succ');
            errOtp.innerHTML = ' ';
            "" == e || null == e ? document.getElementById("leadformOtp").style.borderColor = "#ff0000" : ($("#leadFromOtp").modal("hide"), $("#leadFromNext").modal("show"))
            $scope.addNewLeadUser();
        } else {
            console.log('err');
            errOtp.innerHTML = 'Invalid OTP';

        }

    }


    //new


    function getUiupdate(name) {


        if (name == 'overview' || name == 'Overview') {

            // setTimeout(function(){
            swiper = new Swiper('.swiper-container-about-overview', {
                slidesPerView: 4,
                spaceBetween: 5,
                navigation: {
                    nextEl: '.swiper-ouroverview-next',
                    prevEl: '.swiper-ouroverview-prev'
                },
                breakpoints: {
                    991: {
                        slidesPerView: 'auto',
                        spaceBetween: 5,
                    }
                }
            });
            // },1000);
        } else if (name == 'The Team') {
            setTimeout(function () {
                console.log(name);
                swiper = new Swiper('.swiper-container-about-ourteam', {

                    slidesPerView: 4,
                    spaceBetween: 5,
                    navigation: {
                        nextEl: '.swiper-ourteam-next',
                        prevEl: '.swiper-ourteam-prev'
                    },
                    breakpoints: {
                        991: {
                            slidesPerView: 'auto',
                            spaceBetween: 5,
                        }
                    }

                //     slidesPerView: 4,
                //     spaceBetween: 5,
                //     // scrollbar: {
                //     //     el: '.swiper-scrollbar',
                //     //     hide: true,
                //     // },
                //     navigation: {
                //         nextEl: '.swiper-ourteam-next',
                //         prevEl: '.swiper-ourteam-prev'
                //     },
                //     breakpoints: {
                //         991: {
                //             slidesPerView: 'auto',
                //             spaceBetween: 5,
                //         }
                //     }
                });
            }, 1000);

        } else if (name == 'Our Research Team') {


            setTimeout(function () {
                var swiper = new Swiper('.swiper-container-about-ourteam355', {
                    slidesPerView: 4,
                    autoHeight: true,
                    spaceBetween: 5,
                    scrollbar: {
                        el: '.swiper-scrollbar',
                        hide: true,
                    },
                    navigation: {
                        nextEl: '.swiper-researchteam-next355',
                        prevEl: '.swiper-researchteam-prev355'
                    },
                    breakpoints: {
                        991: {
                            slidesPerView: 'auto',
                            spaceBetween: 5,
                        }
                    }
                });
            }, 1000);
        } else if (name == 'Our Investment Process') {

            //setTimeout(function(){
            var swiper = new Swiper('.swiper-container-about-investment', {
                slidesPerView: 5,
                spaceBetween: 5,
                slideTo: 0,
                breakpoints: {
                    991: {
                        slidesPerView: 'auto',
                        spaceBetween: 5,
                    }
                },
                navigation: {
                    nextEl: '.swiper-inversment-next',
                    prevEl: '.swiper-inversment-prev'
                }
            });
            //  },1000);
        } else if (name == 'Investment Parameters') {

            // setTimeout(function(){
            var swiper = new Swiper('.swiper-container-about-investment', {
                slidesPerView: 4,
                spaceBetween: 4,
                breakpoints: {
                    991: {
                        slidesPerView: 'auto',
                        spaceBetween: 4,
                    }
                },
                navigation: {
                    nextEl: '.swiper-inversment-next',
                    prevEl: '.swiper-inversment-prev'
                }
            });
            //},1000);
        } else if (name == 'Awards') {

            // setTimeout(function(){
            var swiper = new Swiper('.swiper-container-about-investmentAwards', {
                slidesPerView: 5,
                spaceBetween: 5,
                scrollbar: {
                    el: '.swiper-scrollbar',
                    hide: true,

                },
                // 
                breakpoints: {
                    991: {
                        slidesPerView: 'auto',
                        spaceBetween: 5,
                    }
                },
                navigation: {
                    nextEl: '.swiper-inversment-next',
                    prevEl: '.swiper-inversment-prev'
                }
            });
            // },1000);
        }


        //  setTimeout(function(){
        $('#ourEdge').click(function () {

            swiper.slideTo(0)
        });

        var swiper = new Swiper('.swiper-container-about-menu', {
            slidesPerView: 6,
            autoHeight: true,
            spaceBetween: 5,
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            },
            breakpoints: {
                991: {
                    slidesPerView: 'auto',
                    spaceBetween: 5,
                }
            }

        });

        // },1000);
    }

    //load about team data 
    async function loadTeamDetails(tabdata) {
    // let loadTeamDetails = async (tabdata) => {
        $scope.aboutUsarray = [];

        let page = ($stateParams.page == "0") ? 0 : 1;
        var tabpage = tabdata;
        try {
            let reqDataq = {
                url: `${mainAjax.baseUrl}/cms/readAboutus`,
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: toksd
                },
                data: {
                    page: page,
                    teamtype: tabpage
                }
            };
            // let resuaaalt = await mainAjax.requestResolver(reqDataq);

            // $scope.countrycodeval = resuaaalt.data.data;

            mainAjax.resolveRequestData(reqDataq).then(function (res) {

                $scope.aboutUsarray = res.data.data;

                setTimeout(function () {
                    getUiupdate('The Team');
                }, 500);


                setTimeout(function () {
                    document.getElementById('aboutusId').style.display = 'inline-flex';
                    document.getElementById('aboutusIdLoader').style.display = 'none';

                }, 1500);

            }, function (error) {
                if (error.status != 401) {
                    // Notification.error({
                    //     title: 'Error',
                    //     message: 'Oops! something broken'
                    // });
                }

            });

        } catch (e) {
            // $scope.progressbar.complete();
            console.log('e', e);
            if (e.status == 401) {
                $state.go('login');
            }
        }
    }


    //load about team data 
    let loadTeamDetailsreas = async (tabdata) => {
        $scope.aboutUsarrayreas = [];
        var countrycode = localStorageService.get('countrycodepms');
        await getLoginToken();
        let page = ($stateParams.page == "0") ? 0 : 1;
        var tabpage=tabdata;
        try {
            let reqDataq = {
                url: `${mainAjax.baseUrl}/cms/readAboutus`,
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: toksd
                },
                data:{
                    "page":page,
                    "countrycode":countrycode,
                    "teamtype":tabpage
                }
            };
            // let resuaaalt = await mainAjax.requestResolver(reqDataq);
        
            // $scope.countrycodeval = resuaaalt.data.data;

            mainAjax.resolveRequestData(reqDataq).then(function (res) {
              
               $scope.aboutUsarrayreas=res.data.data;
                console.log(res.data.data)
               setTimeout(function(){
                getUiupdate('Our Research Team');
               },500);

               setTimeout(function(){
                document.getElementById('aboutusIdGroup').style.display = 'inline-flex';
                document.getElementById('aboutusIdGroupLoader').style.display = 'none';
                
            },1500);
             

            }, function (error) {
                if (error.status != 401) {
                    Notification.error({
                        title: 'Error',
                        message: 'Oops! something broken'
                    });
                }
    
            });
           
        } catch (e) {
            // $scope.progressbar.complete();
            console.log('e', e);
            if (e.status == 401) {
                $state.go('login');
            }
        }
    }


    // our team members data section end
    $scope.openModelTeam = (name, designation, description, url) => {
        $scope.teamName = name;
        $scope.teamDesignation = designation;
        $scope.teamDesc = description;
        $scope.teamImage = url;
    }

}]);