//Manual  Controller

// angular.module('controllerModule')
app.controller('manualcalculatorController', ['$scope', '$filter', '$http', '$state', 'localStorageService', 'ngProgressFactory', 'Notification', 'mainAjax', '$ngConfirm', function ($scope, $filter, $http, $state, localStorageService, ngProgressFactory, Notification, mainAjax, $ngConfirm) {

    // Hold the view untill the required data's are loaded
    $scope.calcStatus = true;
    $scope.pageReady = false;
    $scope.nodata = false;
    $scope.loaderimg = false;
    $scope.baseUrl = mainAjax.baseUrl;
    $scope.showdetails = true;
    // progress bar configuration
    $scope.pageLoading = false;
    $scope.validResult = []
    $scope.chart = false
    $scope.table = false
    $scope.index = false
    $scope.company = false
    $scope.radioResult = "1"
    $scope.progressbar = ngProgressFactory.createInstance();
    $scope.progressbar.setColor('#0CE713');

    $scope.availableProducts = localStorageService.get('products');
    $scope.kotakStategy = '';
    $scope.pageReady = true;
    $scope.myvalue = false;
    $scope.mydata = false;
    $scope.nodatatable = false
    $scope.errMsg = false
    $scope.errIndexMsg = false;
    $scope.leadForm = {};
    var mapData = new Map();
    mapData.set('OneYear_Ret', 'First Year')
    mapData.set('TwoYear_Ret', 'Second Year')
    mapData.set('ThreeYear_Ret', 'Third Year')
    mapData.set('FourYear_Ret', 'Fourth Year')
    mapData.set('FiveYear_Ret', 'Fifth Year')
    mapData.set('SixYear_Ret', 'Sixth Year')
    mapData.set('SevenYear_Ret', 'Seventh Year')
    mapData.set('EightYear_Ret', 'Eighth Year')
    /***********Manually adding the shares*********** */

    $scope.closeThis = function () {
        $scope.companyByname = '';
        $scope.resultArray = []
        $scope.resultIndexArray = []
        // $scope.$apply()
    }

    $scope.companySearchByName = function (companyByname) {
        if ($scope.companyByname != '' && $scope.companyByname != undefined) {
            if ($scope.companyByname.length >= 2) {
                if ($scope.radioResult == '1') {
                    var reqData = {
                        method: 'POST',
                        url: `https://kotakmfpfapi.cmlinks.com/api/Portfolio/companysearch`,
                        data: { "compname": $scope.companyByname },
                        headers: {
                            'Content-Type': 'application / json'
                        }
                    };
                    mainAjax.resolveRequestData(reqData).then(function (res) {
                        $scope.company = true
                        $scope.index = false
                        $scope.resultArray = res.data;
                    }, function (error) {
                        console.log(error.data, "Company list error")
                    });
                } else {
                    $scope.resultIndexArray = $filter('filter')($scope.resultIndexArrayRes, {
                        IndexName: $scope.companyByname
                    });
                }


            }
        } else {
            $scope.resultArray = []
        }
    }
    $scope.getIndexDetails = function () {
        var reqData = {
            method: 'POST',
            url: `https://kotakmfpfapi.cmlinks.com/api/Portfolio/indexsearch`,
            data: { "Exchange": 'BSE' },
            headers: {
                'Content-Type': 'application / json'
            }
        };
        var reqDataNSE = {
            method: 'POST',
            url: `https://kotakmfpfapi.cmlinks.com/api/Portfolio/indexsearch`,
            data: { "Exchange": 'NSE' },
            headers: {
                'Content-Type': 'application / json'
            }
        };
        Promise.all([mainAjax.resolveRequestData(reqData), mainAjax.resolveRequestData(reqDataNSE)]).then(res => {
            var resultOne = res[0].data
            var resultTwo = res[1].data
            $scope.resultIndexArrayRes = resultOne.concat(resultTwo)
            // console.log($scope.resultIndexArray)
        });
    }
    $scope.getIndexDetails();
    $scope.getcompany = function (companyByname, ISIN) {
        $scope.companyByname = companyByname
        $scope.ISIN = ISIN
        $scope.resultArray = []
        $scope.resultIndexArray = []
    }
    $scope.getcompanyByIndex = function (companyByname, exchange, ISIN) {
        $scope.companyByname = companyByname
        $scope.exchange = exchange;
        $scope.ISIN = ISIN
        $scope.resultArray = []
        $scope.resultIndexArray = []
    }
    $scope.persons = [];

    $scope.addShares = function (val) {
        $scope.errMsg = false;
        $scope.errIndexMsg = false;
        if ($scope.radioResult == '1') {
            if ($scope.ISIN != "" && $scope.shareVlaue != "" && $scope.ISIN != undefined && $scope.shareVlaue != undefined) {

                var data = [{
                    'isinCode': $scope.ISIN,
                    'NumberOfShares': $scope.shareVlaue
                }];
                let reqData = {
                    method: 'POST',
                    url: `https://kotakmfpfapi.cmlinks.com/api/Portfolio/validatescrip`,
                    data: { 'companyNames': data },
                    headers: {
                        'Content-Type': 'application / json'
                    },
                };
                mainAjax.resolveRequestData(reqData).then(function (res) {
                    $scope.resultdata = res.data[0].companyNames

                    var validResults = _.filter(JSON.parse($scope.resultdata), { 'flag': 'Y' });

                    let userecheck = $scope.validResult.some(function (el) {
                        return el.IsinNo === $scope.ISIN;
                    });
                    if (userecheck == false) {
                        $scope.validResult = $scope.validResult.concat(validResults)
                        $scope.mydata = true;
                    }
                    $scope.companyByname = ""
                    $scope.shareVlaue = ""

                }, function (error) {
                    console.log(error.data, "header company list error")
                });
            } else {
                $scope.errMsg = true
            }
        } else {
            $scope.errIndexMsg = false;
            $scope.errMsg = false;
            if ($scope.ISIN != "" && $scope.ISIN != undefined) {
                var data = {
                    'CompanyName': $scope.companyByname,
                    'Nofshares': '',
                    'indexCode': $scope.ISIN
                };
                console.log($scope.validResult.length);
                if ($scope.validResult.length < 1) {
                    let userecheck = $scope.validResult.some(function (el) {
                        return el.indexCode === $scope.ISIN;
                    });
                    if (userecheck == false) {
                        $scope.validResult = $scope.validResult.concat(data)
                        $scope.mydata = true;
                    }
                    $scope.companyByname = ""

                } else {
                    $scope.errIndexMsg = true
                    $scope.companyByname = ""
                    $scope.resultIndexArray = [];
                }
            }
            else {
                $scope.errMsg = true
            }

        }
    }
    $scope.removePerson = function (index) {
        $scope.validResult.splice(index, 1);
    }

    $scope.getYears = function () {

        $scope.arr = []; $scope.fromDate
        if ($scope.kotakStategy == 'Small and Midcap Strategy') {
            var de = '31/july/2012'
            $scope.fromDate = de
            var date1 = '2012-07-31';
            var date2 = moment(new Date()).format("YYYY-MM-DD");
            var diff = moment(date2).diff(date1, 'years')
        }
        else if ($scope.kotakStategy == 'Special Situations Value Portfolio') {
            var de = '30/apr/2012'
            $scope.fromDate = de
            var date1 = '2012-04-30';
            var date2 = moment(new Date()).format("YYYY-MM-DD");
            var diff = moment(date2).diff(date1, 'years')
        } else {
            var de = '31/july/2016'
            $scope.fromDate = de
            var date1 = '2016-07-31';
            var date2 = moment(new Date()).format("YYYY-MM-DD");
            var diff = moment(date2).diff(date1, 'years')

        }
        for (var i = 1; i <= diff; i++) {
            $scope.arr.push({
                'val': i + ' Years',
                'year': i
            })
        }
    }
    $scope.getDataForchart = function () {

        $scope.table = false
        $scope.chart = true

        if ($scope.radioResult == '1') {
            $scope.nodata = false

            var years = $scope.kotakStategyyear + 'y'
            var fromDate = $scope.fromDate
            var toDate = moment(new Date()).format("DD/MMM/YYYY")
            var array = []
            if ($scope.validResult.length > 0) {
                for (let data of $scope.validResult) {
                    let obj = { isinCode: data.IsinNo, numberOfShares: data.Nofshares }
                    array = array.concat(obj)
                }
            }
            let data = {
                userPortfolio: array, viewType: 'chart', year: years, Exchange: 'NSE', indexCode: 20559, fromdate: fromDate, todate: toDate
            }
            var reqData = {
                method: 'POST',
                url: `https://kotakmfpfapi.cmlinks.com/api/Portfolio/portfolioreturn`,
                data: data,
                headers: {
                    'Content-Type': 'application / json'
                }
            };
            let chartDataD = {
                "year": $scope.kotakStategyyear, "strategy": $scope.kotakStategy
            }
            let reqDataD = {
                url: `${mainAjax.baseUrlLocal}/cms/graphData`,
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: chartDataD
            };
            $scope.loaderimg = true;
            Promise.all([mainAjax.resolveRequestData(reqData), mainAjax.resolveRequestData(reqDataD)]).then(res => {
                var resultOne = JSON.parse(res[0].data[0].result.userPortfolio.pms)
                var resultTwo = res[1].data.data.data

                if (resultOne.length > 0 && resultTwo.length > 0) {
                    var mapped = _.reduce(resultTwo, function (acc, item) {
                        let dateNew = item.date1
                        let d = dateNew.split('.000Z')
                        acc[d[0]] = item.datad;
                        return acc;
                    }, {});
                    var lstLumpsumAmountInvestedObj = [];
                    var lstLumpsumSchemeDataObj = [];
                    var lstLumpsumOtherDataObj = [];
                    var schemeSeries = {};
                    var investedSeries = {};
                    var otherSeries = {};
                    var resultLength = resultOne.length
                    for (var i = 0; i < resultLength; i++) {
                        var labels = Object.keys(mapped)
                        if (labels.includes(resultOne[i].date1)) {

                            var investmentDate = 0;
                            let yearF = moment(resultOne[i].date1).format('DD-MM-YYYY')
                            var investmentDateArr = yearF.split("-");
                            var date = Date.UTC(parseInt(investmentDateArr[2]), investmentDateArr[1], investmentDateArr[0]);
                            investmentDate = date;

                            var strInvestedAmount = !isNaN(parseFloat(resultOne[i].SchRebaseMktValue)) ? parseFloat(resultOne[i].SchRebaseMktValue).toFixed(2) : 0;
                            var strSchemeValue = !isNaN(parseFloat(resultOne[i].RebaseMktValue)) ? parseFloat(resultOne[i].RebaseMktValue).toFixed(2) : 0;
                            var strOtherValue = !isNaN(parseFloat(mapped[resultOne[i].date1])) ? parseFloat(mapped[resultOne[i].date1]).toFixed(2) : 0;

                            lstLumpsumAmountInvestedObj.push([investmentDate, parseFloat(strInvestedAmount)]);
                            lstLumpsumSchemeDataObj.push([investmentDate, parseFloat(strSchemeValue)]);
                            lstLumpsumOtherDataObj.push([investmentDate, parseFloat(strOtherValue)]);
                        }
                    }
                    investedSeries.name = "Benchmark Value";
                    investedSeries.data = lstLumpsumAmountInvestedObj;
                    investedSeries.color = '#605ca8';

                    schemeSeries.name = "Portfolio Value";
                    schemeSeries.data = lstLumpsumSchemeDataObj;
                    schemeSeries.color = '#f7941d';

                    otherSeries.name = $scope.kotakStategy
                    otherSeries.data = lstLumpsumOtherDataObj;
                    otherSeries.color = '#77c4ec';
                    $scope.pointsData = []
                    $scope.pointsData.push(investedSeries);
                    $scope.pointsData.push(schemeSeries);
                    $scope.pointsData.push(otherSeries)
                    $scope.loaderimg = false;
                    $scope.$apply()
                    $scope.chartData();
                } else {
                    console.log("jio")
                    $scope.loaderimg = false;
                    $scope.nodata = true;
                    $scope.$apply()

                }
            })
        } else {
            $scope.nodata = false
            var years = $scope.kotakStategyyear + 'y'
            var fromDate = $scope.fromDate
            var toDate = moment(new Date()).format("DD/MMM/YYYY")
            if ($scope.exchange == 'BSE') {
                var index2value = '20558'
            } else {
                var index2value = '20559'
            }
            var indexCode1 = ''
            if ($scope.validResult.length > 0) {
                indexCode1 = $scope.validResult[0].indexCode
            }
            let data = {
                indexCode1: indexCode1, indexCode2: index2value, viewType: 'chart', year: years, fromdate: fromDate, todate: toDate
            }
            var reqData = {
                method: 'POST',
                url: `https://kotakmfpfapi.cmlinks.com/api/Portfolio/benchmarkreturn`,
                data: data,
                headers: {
                    'Content-Type': 'application / json'
                }
            };
            let chartDataD = {
                "year": $scope.kotakStategyyear, "strategy": $scope.kotakStategy
            }
            let reqDataD = {
                url: `${mainAjax.baseUrlLocal}/cms/graphData`,
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: chartDataD
            };
            $scope.loaderimg = true;
            Promise.all([mainAjax.resolveRequestData(reqData), mainAjax.resolveRequestData(reqDataD)]).then(res => {
                var resultOne = JSON.parse(res[0].data[0].result.userPortfolio.pms)
                var resultTwo = res[1].data.data.data

                if (resultOne.length > 0 && resultTwo.length > 0) {
                    var mapped = _.reduce(resultTwo, function (acc, item) {
                        let dateNew = item.date1
                        let d = dateNew.split('.000Z')
                        acc[d[0]] = item.datad;
                        return acc;
                    }, {});
                    var lstLumpsumSchemeDataObj = [];
                    var lstLumpsumOtherDataObj = [];
                    var schemeSeries = {};
                    var otherSeries = {};
                    let lIntResultLength = resultOne.length;
                    for (var i = 0; i < lIntResultLength; i++) {
                        var labels = Object.keys(mapped)
                        if (labels.includes(resultOne[i].date1)) {
                            var investmentDate = 0;
                            let yearF = moment(resultOne[i].date1).format('DD-MM-YYYY')
                            var investmentDateArr = yearF.split("-");
                            var date = Date.UTC(parseInt(investmentDateArr[2]), investmentDateArr[1], investmentDateArr[0]);
                            investmentDate = date;
                            var strSchemeValue = !isNaN(parseFloat(resultOne[i].RebaseMktValue)) ? parseFloat(resultOne[i].RebaseMktValue).toFixed(2) : 0;
                            var strOtherValue = !isNaN(parseFloat(mapped[resultOne[i].date1])) ? parseFloat(mapped[resultOne[i].date1]).toFixed(2) : 0;
                            lstLumpsumSchemeDataObj.push([investmentDate, parseFloat(strSchemeValue)]);
                            lstLumpsumOtherDataObj.push([investmentDate, parseFloat(strOtherValue)]);
                        }
                    }
                    schemeSeries.name = "Portfolio Value";
                    schemeSeries.data = lstLumpsumSchemeDataObj;
                    schemeSeries.color = '#f7941d';

                    otherSeries.name = $scope.kotakStategy
                    otherSeries.data = lstLumpsumOtherDataObj;
                    otherSeries.color = '#77c4ec';
                    $scope.pointsData = []

                    $scope.pointsData.push(schemeSeries);
                    $scope.pointsData.push(otherSeries)
                    $scope.loaderimg = false;
                    $scope.$apply()
                    $scope.chartData();
                } else {
                    $scope.loaderimg = false;
                    $scope.nodata = true
                    $scope.$apply()

                }
            })
        }

    }
    $scope.getDataFortable = function () {
        $scope.chart = false
        $scope.table = true

        if ($scope.radioResult == '1') {
            $scope.nodatatable = false
            var years = $scope.kotakStategyyear + 'y'
            var fromDate = $scope.fromDate
            var toDate = moment(new Date()).format("DD/MMM/YYYY")
            var array = []
            if ($scope.validResult.length > 0) {
                for (let data of $scope.validResult) {
                    let obj = { isinCode: data.IsinNo, numberOfShares: data.Nofshares }
                    array = array.concat(obj)
                }
            }
            let data = {
                userPortfolio: array, viewType: 'table', year: years, Exchange: 'NSE', indexCode: 20559, fromdate: fromDate, todate: toDate
            }
            var reqData = {
                method: 'POST',
                url: `https://kotakmfpfapi.cmlinks.com/api/Portfolio/portfolioreturn`,
                data: data,
                headers: {
                    'Content-Type': 'application / json'
                }
            };
            let chartDataD = {
                "year": $scope.kotakStategyyear, "strategy": $scope.kotakStategy
            }
            let reqDataD = {
                url: `${mainAjax.baseUrlLocal}/cms/tableData`,
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: chartDataD
            };
            Promise.all([mainAjax.resolveRequestData(reqData), mainAjax.resolveRequestData(reqDataD)]).then(res => {
                $scope.responseForTable = JSON.parse(res[0].data[0].result.userPortfolio.yearlyReturnPercentage)
                var resultTwo = res[1].data.data.data
                if ($scope.responseForTable.length > 0) {
                    var labels = Object.keys($scope.responseForTable[0])
                    if (resultTwo.length > 0) {
                        var objR = {}
                        for (let data of resultTwo) {
                            objR = Object.assign(objR, data);
                        }
                        $scope.responseForTable.unshift(objR)
                        $scope.$apply();
                    }
                    var add = []
                    for (let data of labels) {
                        add.push(mapData.get(data));
                    }
                    $scope.esponseKeys = _.compact(add)
                    $scope.esponseKey = Object.keys($scope.responseForTable[0])
                    $scope.$apply();
                } else {
                    $scope.nodatatable = true
                }


            });
        } else {
            $scope.nodatatable = false
            var years = $scope.kotakStategyyear + 'y'
            var fromDate = $scope.fromDate
            var toDate = moment(new Date()).format("DD/MMM/YYYY")
            if ($scope.exchange == 'BSE') {
                var index2value = '20558'
            } else {
                var index2value = '20559'
            }
            var indexCode1 = ''
            if ($scope.validResult.length > 0) {
                indexCode1 = $scope.validResult[0].indexCode
            }
            let data = {
                indexCode1: indexCode1, indexCode2: index2value, viewType: 'table', year: years, fromdate: fromDate, todate: toDate
            }
            var reqData = {
                method: 'POST',
                url: `https://kotakmfpfapi.cmlinks.com/api/Portfolio/benchmarkreturn`,
                data: data,
                headers: {
                    'Content-Type': 'application / json'
                }
            };
            let chartDataD = {
                "year": $scope.kotakStategyyear, "strategy": $scope.kotakStategy
            }
            let reqDataD = {
                url: `${mainAjax.baseUrlLocal}/cms/tableData`,
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: chartDataD
            };

            Promise.all([mainAjax.resolveRequestData(reqData), mainAjax.resolveRequestData(reqDataD)]).then(res => {
                $scope.responseForTable = JSON.parse(res[0].data[0].result.userPortfolio.yearlyReturnPercentage)
                var resultTwo = res[1].data.data.data
                if ($scope.responseForTable.length > 0) {
                    var labels = Object.keys($scope.responseForTable[0])
                    if (resultTwo.length > 0) {
                        var objR = {}
                        for (let data of resultTwo) {
                            objR = Object.assign(objR, data);
                        }
                        $scope.responseForTable.unshift(objR)
                        console.log($scope.responseForTable)
                        $scope.$apply();
                    }
                    var add = []
                    for (let data of labels) {
                        add.push(mapData.get(data));
                    }
                    $scope.esponseKeys = _.compact(add)
                    $scope.esponseKey = Object.keys($scope.responseForTable[0])
                    $scope.$apply();
                } else {
                    $scope.nodatatable = true
                }


            });
        }

    }
    $scope.hideNumber = function () {
        if ($scope.leadForm.mobileNumber) {
            let number = $scope.leadForm.mobileNumber;
            let totalCharacter = $scope.leadForm.mobileNumber.length;
            let char = '';
            for (let i = 0; i < totalCharacter; i++) {
                char += '*'
            }
            char = char.substring(0, totalCharacter - 2) + number.charAt(totalCharacter - 2) + number.charAt(totalCharacter - 1);
            return char;

        }
    }
    $scope.hideDropdown = function () {
        console.log("hi");
        $scope.company = false;
        $scope.resultArray = [];
        $scope.$apply();
    }
    $scope.clearall = function () {
        $scope.validResult = [];
        $scope.fileName = ""
        $scope.mydata = false
        $scope.table = false
        $scope.chart = false
        $scope.errMsg = false;
        $scope.errIndexMsg = false;
        $scope.kotakStategy = ""
        $scope.kotakStategyyear = ""
        $scope.companyByname = ""
        $scope.shareVlaue = ""
    }
    $scope.chartData = () => {
        Highcharts.chart('container', {

            xAxis: {
                title: {
                    text: 'Investment Period'
                },
                type: "datetime",
                dateTimeLabelFormats: {
                    day: "%e-%b",
                    week: "%e-%b",
                    month: "%b-%y",
                    year: "%Y"
                }
            },
            title: { text: '' },
            tooltip: {
                valueDecimals: 2,
                shared: !0,
                crosshairs: !0,
                headerFormat: "<small>{point.key}</small><br>",
                pointFormat: "<span >{series.name}</span>: <b>{point.y}</b><br/>",
                xDateFormat: "%b %d, %Y"
            },
            yAxis: {
                title: {
                    text: 'Value of Investment'
                }
            },
            legend: {
                layout: 'horizontal',
                align: 'center',
                verticalAlign: 'bottom'
            },

            plotOptions: {
                series: {
                    label: {
                        connectorAllowed: false
                    }
                }
            },
            credits: {
                enabled: false
            },
            exporting: { enabled: false },
            series: $scope.pointsData,

            responsive: {
                rules: [{
                    condition: {
                        maxWidth: 'auto'
                    },
                    chartOptions: {
                        legend: {
                            layout: 'horizontal',
                            align: 'center',
                            verticalAlign: 'bottom'
                        }
                    }
                }]
            }

        });
    }
    $scope.onchangeTest = function (that) {
        console.log('ss');
        console.log(that);
        $scope.selecteditems=that;
      //  console.log($scope.product.name);
    }
    $scope.onchangeProduct = function () {
        $scope.currentCatValue = productData;
        $scope.categoryValues = categoryValues;
        console.log( $scope.currentCatValue);
        console.log( $scope.categoryValues);
    }
    var toksd;
    async function getLoginToken() {
        // var loginData = {
        //     "email": "senthilnathan.k@kotak.com",
        //     "password": "Abnegation@123",
        //     "is_admin": 1
        // };
        var loginData = {
            email: "eyJlbWFpbCI6InNlbnRoaWxuYXRoYW4ua0Brb3Rhay5jb20iLCJwYXNzd29yZCI6IkFibmVnYXRpb25AMTIzIn0="
        }
        let reqData = {
            url: `${mainAjax.baseUrl}/login`,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            data: loginData
        };
         await mainAjax.resolveRequestData(reqData).then(function (result) {
            console.log(result);
            toksd = result.data.data.token
            console.log("This is the token : " + toksd);
            return toksd;      
        }, function (error) {
            Notification.error({
                message: 'Oops! something broken'
            });
        });
    }
    
    
        $scope.addNewLeadUser = async () =>  {
            await getLoginToken();
            var name =document.getElementById("userName").value;
            var mobile=document.getElementById("mobileNumber").value;
            var email= document.getElementById("email").value;
            var dataselect=document.getElementById("selesctproduct").value;
                    let lObjNewLeadUser = {
                        'name':name,
                        'phonenumber':mobile,
                        'email':email,
                        'product1':$scope.selecteditems[0] !=undefined?$scope.selecteditems[0]:null,
                        'product2':$scope.selecteditems[1] !=undefined?$scope.selecteditems[1]:null,
                        'product3':$scope.selecteditems[2] !=undefined?$scope.selecteditems[2]:null,
                        'product4':$scope.selecteditems[3] !=undefined?$scope.selecteditems[3]:null,
                        'product5':$scope.selecteditems[4] !=undefined?$scope.selecteditems[4]:null,
                        'product6':$scope.selecteditems[5] !=undefined?$scope.selecteditems[5]:null,
                        'product7':$scope.selecteditems[6] !=undefined?$scope.selecteditems[6]:null
                    }
                    console.log(lObjNewLeadUser);
           
                    //let sessionData = localStorage.getItem('kspswk_aa');
            
             let reqData = {
                 url: `${mainAjax.baseUrl}/product/createLead`,
                 method: 'POST',
                 headers: {
                     'Content-Type': 'application/json',
                     Authorization: toksd//"Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MjE1LCJsb2dnZWRJbiI6IjE1NDI5NzU5MjAwMzciLCJpYXQiOjE1NDI5NzU5MjB9.yX9C42Gz1aRNDr1QYyKCG1HpXtCa0vHP7mOd5uaHr-w"
                 },
                 data: lObjNewLeadUser
             };
             console.log(`${mainAjax.baseUrl}/product/createLead`);
             mainAjax.resolveRequestData(reqData).then(function (result) {
                 console.log(result);
               
             }, function (error) {
                 Notification.error({
                     message: 'Oops! something broken'
                 });
             });
     
         }

    $scope.getRandomIntOTP = function () {

            let min = 1000;
            let max = 9999;
            return Math.floor(Math.random() * (max - min + 1)) + min;
        }
        var getrandomNumber;
        
    $scope.nextOtppage = function () {

             getrandomNumber = $scope.getRandomIntOTP();
            var phoneNumber = document.getElementById("mobileNumber").value;
            //var typeProductVAl = document.getElementById('typeProductVAl').value;
        
        
            var uRLPath = "https://sms6.routesms.com:8443/bulksms/bulksms?username=ktamc&password=mtMZwh0C&type=0&dlr=1&source=KOTKMF&destination="+phoneNumber+"&&message=Welcome to Kotak. The OTP is:" +getrandomNumber;
        
            $.ajax({  
                url: uRLPath,  
                type: 'POST',  
                success: function(succ){
                },
                error: function(err){
                }   
            });
            ($("#leadFrom").modal("hide"), $("#leadFromOtp").modal("show"))
        }
        var timerOn = true;
        
        function timer(remaining) {
            
          var m = Math.floor(remaining / 60);
          var s = remaining % 60;
          
          m = m < 10 ? '0' + m : m;
          s = s < 10 ? '0' + s : s;
          var getTimer = document.getElementById('timer');
            if(!getTimer){
                //
            } else{
          document.getElementById('timer').innerHTML = m + ':' + s;

            }
          remaining -= 1;
          
          if(remaining >= 0 && timerOn) {
            setTimeout(function() {
                timer(remaining);
            }, 1000);
            return;
          }
        
          if(!timerOn) {
            // Do validate stuff here
            window.onload = function() {
                window.setTimeout(setDisabled, 1000);
            }
            return;
          }
          
          // Do timeout stuff here
          setTimeout(function() {
            document.getElementById('resendEnable').disabled = false;
        }, 1000);
        }
        timer(60);
    $scope.resendClick = function () {
            timer(60);
            setTimeout(function() {
                document.getElementById('resendEnable').disabled = true;
            }, 1000);
            $scope.nextOtppage();
            
        }
         
    $scope.leadFormValidation = function () {

            var e = document.getElementById("userName").value,
                t = document.getElementById("mobileNumber").value,
                l = document.getElementById("email").value;
            "" != e && null != e || "" != t && null != t || "" != l && null != l ? "" == e || null == e ? document.getElementById("userName").style.borderColor = "#ff0000" : "" == t || null == t ? document.getElementById("mobileNumber").style.borderColor = "#ff0000" : "" == l || null == l ? document.getElementById("email").style.borderColor = "#ff0000" : $scope.nextOtppage() : (document.getElementById("userName").style.borderColor = "#ff0000", document.getElementById("mobileNumber").style.borderColor = "#ff0000", document.getElementById("email").style.borderColor = "#ff0000")
        }

    $scope.leadotpValidation = function () {

            var e = document.getElementById("leadformOtp").value;
            var errOtp = document.getElementById('erroOtp');
            
            if(getrandomNumber == e ){
                console.log('succ');
                errOtp.innerHTML = ' ';
                "" == e || null == e ? document.getElementById("leadformOtp").style.borderColor = "#ff0000" : ($("#leadFromOtp").modal("hide"), $("#leadFromNext").modal("show"))
                $scope.addNewLeadUser();
            } else {
                console.log('err');
                errOtp.innerHTML = 'Invalid OTP';
        
            }   
            
        }
    
}]).directive('ngEnter', function () { //a directive to 'enter key press' in elements with the "ng-enter" attribute

    return function (scope, element, attrs) {

        element.bind("keydown", function (event) {
            if (event.which === 13) {
                scope.$apply(function () {
                    scope.$eval(attrs.ngEnter);
                });

                event.preventDefault();
            }
        });
    };

});