//Download Controller

// angular.module('controllerModule')
app.controller('downloadsController', ['$scope', '$http', '$state', 'localStorageService', 'ngProgressFactory', 'Notification', 'mainAjax', '$ngConfirm', '$filter', function ($scope, $http, $state, localStorageService, ngProgressFactory, Notification, mainAjax, $ngConfirm, $filter){


    //Pagination
    // $scope.currentPagePagination = 0;
    // $scope.pageSize = 1;

  
    // $scope.q = '';
    // $scope.getData = function (that) {
    //     $scope.selectedarray = that;
    //     console.log(that)
    //     console.log($scope.q)
    //     return $filter('filter')($scope.selectedarray, $scope.q)
    // }
    // $scope.selectedarray = [];
    // $scope.numberOfPages = function (that) {
    //     console.log(that)
    //     $scope.selectedarray = that;
    //     if (!!$scope.getData(that) && $scope.getData(that).length) {
    //         return Math.ceil($scope.getData(that).length / $scope.pageSize);
    //     }
    // }

    // $scope.numberOfPages = function (that) {
        // if (!!$scope.getData(that) && $scope.getData(that).length) {
            // return Math.ceil(that / $scope.pageSize);
        // }
    // }

    // Hold the view untill the required data's are loaded
    $scope.pageReady = false;

    $scope.baseUrl = mainAjax.baseUrl;

    $scope.downloadStatus = true;

    // progress bar configuration
    $scope.pageLoading = false;
    $scope.progressbar = ngProgressFactory.createInstance();
    $scope.progressbar.setColor('#0CE713');

    //Get saved projects from home screen load
    $scope.availableProducts = localStorageService.get('products');

    $scope.pageReady = true;

    $scope.leadForm = {};

    $scope.hideNumber = () => {
        if ($scope.leadForm.mobileNumber) {
            let number = $scope.leadForm.mobileNumber;
            let totalCharacter = $scope.leadForm.mobileNumber.length;
            let char = '';
            for (let i = 0; i < totalCharacter; i++) {
                char += '*'
            }
            char = char.substring(0, totalCharacter - 2) + number.charAt(totalCharacter - 2) + number.charAt(totalCharacter - 1);
            return char;

        }
    }
    $scope.date = new Date();

    $scope.documents = [//{
        //         name: 'KYC Non Individual',
        // 	filePath: 'http://kotakpms.com/documents/Disc.pdf'
        // //        filePath: 'http://kotakportfoliomanagementservices.com/documents/KYC (non_individual) (1).pdf'
        //     }, {
        //         name: 'KYC Individual',
        // 	filePath: 'http://kotakpms.com/documents/KYC(individual).pdf'
        //         //filePath: 'http://kotakportfoliomanagementservices.com/documents/KYC(individual).pdf'
        //     }, {
        //         name: 'Demat Individual',
        // 	filePath: 'http://kotakpms.com/documents/Demat(Individual).pdf'
        //         //filePath: 'http://kotakportfoliomanagementservices.com/documents/Demat(Individual).pdf'
        //     }
        {
            name: 'Disclosure Document',
            filePath: 'http://kotakpms.com/documents/DisclosureDocument.pdf'
        }
    ]

    $scope.documentsCustomers = [
        {
            name: 'Disclosure Document',
            filePath: 'http://kotakpms.com/documents/DisclosureDocument.pdf',
            type: 'pdf',
            date: '--'
        },
        {
            name: 'Investor Charter for PMS ',
            filePath: 'http://kotakpms.com/documents/PMSInvestmentcharter.pdf',
            type: 'pdf',
            date: '--'
        }, {
            name: 'Lodge a complaint on Scores',
            filePath: 'https://scores.gov.in/scores/Welcome.html#',
            type: 'view',
            date: '--'
        },
        {
            name: 'Download Scores App',
            filePath: 'http://onelink.to/2wgumn',
            type: 'view',
            date: '--'
        },
        {
            name: 'Disclosure of Investor complaints',
            filePath: 'http://kotakpms.com/documents/ComplainDataWebsiteUpload.pdf',
            type: 'pdf',
            date: 'Jan 2021 '
        }




    ]


    $scope.onchangeTest = function (that) {
        console.log('ss');
        console.log(that);
        $scope.selecteditems = that;
        //  console.log($scope.product.name);
    }
    $scope.onchangeProduct = function () {
        $scope.currentCatValue = productData;
        $scope.categoryValues = categoryValues;
        console.log($scope.currentCatValue);
        console.log($scope.categoryValues);
    }
    var toksd;
    async function getLoginToken() {
        // var loginData = {
        //     "email": "senthilnathan.k@kotak.com",
        //     "password": "Abnegation@123",
        //     "is_admin": 1
        // };
        var loginData = {
            email: "eyJlbWFpbCI6InNlbnRoaWxuYXRoYW4ua0Brb3Rhay5jb20iLCJwYXNzd29yZCI6IkFibmVnYXRpb25AMTIzIn0="
        }
        let reqData = {
            url: `${mainAjax.baseUrl}/login`,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            data: loginData
        };
        await mainAjax.resolveRequestData(reqData).then(function (result) {
            console.log(result);
            toksd = result.data.data.token
            console.log("This is the token : " + toksd);
            return toksd;
        }, function (error) {
            Notification.error({
                message: 'Oops! something broken'
            });
        });
    }


    $scope.addNewLeadUser = async () => {
        await getLoginToken();
        var name = document.getElementById("userName").value;
        var mobile = document.getElementById("mobileNumber").value;
        var email = document.getElementById("email").value;
        var dataselect = document.getElementById("selesctproduct").value;
        let lObjNewLeadUser = {
            'name': name,
            'phonenumber': mobile,
            'email': email,
            'product1': $scope.selecteditems[0] != undefined ? $scope.selecteditems[0] : null,
            'product2': $scope.selecteditems[1] != undefined ? $scope.selecteditems[1] : null,
            'product3': $scope.selecteditems[2] != undefined ? $scope.selecteditems[2] : null,
            'product4': $scope.selecteditems[3] != undefined ? $scope.selecteditems[3] : null,
            'product5': $scope.selecteditems[4] != undefined ? $scope.selecteditems[4] : null,
            'product6': $scope.selecteditems[5] != undefined ? $scope.selecteditems[5] : null,
            'product7': $scope.selecteditems[6] != undefined ? $scope.selecteditems[6] : null
        }
        console.log(lObjNewLeadUser);

        //let sessionData = localStorage.getItem('kspswk_aa');

        let reqData = {
            url: `${mainAjax.baseUrl}/product/createLead`,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: toksd//"Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MjE1LCJsb2dnZWRJbiI6IjE1NDI5NzU5MjAwMzciLCJpYXQiOjE1NDI5NzU5MjB9.yX9C42Gz1aRNDr1QYyKCG1HpXtCa0vHP7mOd5uaHr-w"
            },
            data: lObjNewLeadUser
        };
        console.log(`${mainAjax.baseUrl}/product/createLead`);
        mainAjax.resolveRequestData(reqData).then(function (result) {
            console.log(result);

        }, function (error) {
            Notification.error({
                message: 'Oops! something broken'
            });
        });

    }

    $scope.getRandomIntOTP = function () {

        let min = 1000;
        let max = 9999;
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }
    var getrandomNumber;

    $scope.nextOtppage = function () {

        getrandomNumber = $scope.getRandomIntOTP();
        var phoneNumber = document.getElementById("mobileNumber").value;
        //var typeProductVAl = document.getElementById('typeProductVAl').value;

   
            let details = {mobileNo: phoneNumber,  id:getrandomNumber }            
                try {
                    let reqDataq = {
                        url: `${mainAjax.baseUrl}/cms/sms6`,
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: toksd
                        },
                        data: {
                            details:btoa(JSON.stringify(details)),
                        }
                    };
                    mainAjax.resolveRequestData(reqDataq).then(function (res) {       
                        ($("#leadFrom").modal("hide"), $("#leadFromOtp").modal("show"))
                    }, function (error) {
                        if (error.status != 401) {                           
                        }        
                    });        
                } catch (e) {
                    console.log('e', e);
                    if (e.status == 401) {
                        $state.go('login');
                    }
                }
            


        // $.ajax({
        //     url: uRLPath,
        //     type: 'POST',
        //     success: function (succ) {
        //     },
        //     error: function (err) {
        //     }
        // });
        // ($("#leadFrom").modal("hide"), $("#leadFromOtp").modal("show"))
    }



    var timerOn = true;

    function timer(remaining) {

        var m = Math.floor(remaining / 60);
        var s = remaining % 60;

        m = m < 10 ? '0' + m : m;
        s = s < 10 ? '0' + s : s;
        var getTimer = document.getElementById('timer');
        if (!getTimer) {
            //
        } else {
            document.getElementById('timer').innerHTML = m + ':' + s;

        }
        remaining -= 1;

        if (remaining >= 0 && timerOn) {
            setTimeout(function () {
                timer(remaining);
            }, 1000);
            return;
        }

        if (!timerOn) {
            // Do validate stuff here
            window.onload = function () {
                window.setTimeout(setDisabled, 1000);
            }
            return;
        }

        // Do timeout stuff here
        setTimeout(function () {
            document.getElementById('resendEnable').disabled = false;
        }, 1000);
    }
    timer(60);
    $scope.resendClick = function () {
        timer(60);
        setTimeout(function () {
            document.getElementById('resendEnable').disabled = true;
        }, 1000);
        $scope.nextOtppage();

    }

    $scope.leadFormValidation = function () {

        var e = document.getElementById("userName").value,
            t = document.getElementById("mobileNumber").value,
            l = document.getElementById("email").value;
        "" != e && null != e || "" != t && null != t || "" != l && null != l ? "" == e || null == e ? document.getElementById("userName").style.borderColor = "#ff0000" : "" == t || null == t ? document.getElementById("mobileNumber").style.borderColor = "#ff0000" : "" == l || null == l ? document.getElementById("email").style.borderColor = "#ff0000" : $scope.nextOtppage() : (document.getElementById("userName").style.borderColor = "#ff0000", document.getElementById("mobileNumber").style.borderColor = "#ff0000", document.getElementById("email").style.borderColor = "#ff0000")
    }

    $scope.leadotpValidation = function () {

        var e = document.getElementById("leadformOtp").value;
        var errOtp = document.getElementById('erroOtp');

        if (getrandomNumber == e) {
            console.log('succ');
            errOtp.innerHTML = ' ';
            "" == e || null == e ? document.getElementById("leadformOtp").style.borderColor = "#ff0000" : ($("#leadFromOtp").modal("hide"), $("#leadFromNext").modal("show"))
            $scope.addNewLeadUser();
        } else {
            console.log('err');
            errOtp.innerHTML = 'Invalid OTP';

        }

    }

    $scope.currentPage = 'Customer'
    $scope.downloadlistLocal = '';
    $scope.getDownloadList = async () =>{
        if(hideonloader){
            hideonloader.style.display = 'none'
        }
        if(loaderBlockWrapper){
            loaderBlockWrapper.style.display = 'block'
        }
        
        $scope.downloadlist = '';
        try {

            let reqData = {
                method: 'GET',
                url: `${mainAjax.baseUrl}/cms/readDownloadList?downloadtype=` + $scope.currentPage,
                headers: {
                    'Content-Type': 'application / json'
                },
            };
            await mainAjax.resolveRequestData(reqData).then(function (result) {
                $scope.progressbar.complete();

                $scope.downloadlist = result.data.data;
                $scope.downloadlist = $scope.downloadlist.filter(function(item){
                    return item.subheaderid == null;         
                });
                var months = ["January", "February", "March", "April", "May", "June",
                "July", "August", "September", "October", "November", "December"];

                $scope.downloadlist.forEach((element) => {

                    let getMonth = element.month;
                    let getYear = element.year.slice().sort((a, b) => (b - a));
                
                    getMonth.sort((a, b) => {
                        return months.indexOf(a)
                            - months.indexOf(b);
                    });
                    element.newSortMonth = getMonth;
                    element.newSortYear = getYear;

                });
                if(hideonloader){
                    hideonloader.style.display = 'block'
                }
                if(loaderBlockWrapper){
                    loaderBlockWrapper.style.display = 'none'
                }
               
                $scope.downloadlistLocal = $scope.downloadlist;
            }, function (error) {
                Notification.error({
                    message: 'Oops! something broken'
                });
            });
        } catch (e) {
            Notification.error({
                message: 'Oops! something broken'
            });
        }

    }

    $scope.getDownloadList();

    $scope.currentPageFunc = function (that) {
        $scope.currentPage = that;
        $scope.getDownloadList();
    }

    // $scope.loaderimg = false;
    let hideonloader = document.getElementById('hideonloader')
    let loaderBlockWrapper = document.getElementById('loaderBlockWrapper')
   
    $scope.subheaderList = [];
    // $scope.getlistByHeaderId = function (that) {
        async function getlistByHeaderId(that) {
            let reqData = {
                url: `${mainAjax.baseUrl}/cms/getByheaderId?headerid=` + that,
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            };
            await mainAjax.resolveRequestData(reqData).then(function (result) {
             
                $scope.subheaderList = (result.data.data);
               
                $scope.subheaderList.forEach((element2,index2) => {
                $scope.downloadlist.forEach((element1,index) => {
                    
                   
                      
                        if(element1.headerid == element2.headerid){
                            element1.subheader =  element2;
                     
                        }
                    });
                });
                console.log($scope.downloadlist)
                return result.data.data;
               

                    
                // console.log("This is the token : " + toksd);
                // return toksd;
            }, function (error) {
                Notification.error({
                    message: 'Oops! something broken'
                });
            });




    }



    $scope.selectedItemyear = '';
    $scope.selectedItemmonth = '';

    $scope.update = (that, headerid) => {   
        console.log(that)    
        console.log(headerid)
        $scope.selectedItemyear = that;
        if(that.idmonth== undefined){
            document.getElementById('infoMonthSelect'+that.id).style.display = 'inline-flex';
            document.getElementById('infoYearSelect'+that.id).style.display = 'none';

        }else {
            document.getElementById('infoMonthSelect'+that.id).style.display = 'none';
            document.getElementById('infoYearSelect'+that.id).style.display = 'none';
            commonFilter(that, headerid,'year');
        }
       
    }

    $scope.updateMonth = (that, headerid) => {  
        console.log(that)    
        console.log(headerid) 
        $scope.selectedItemmonth = that;
        if(that.idyear== undefined){
            document.getElementById('infoMonthSelect'+that.id).style.display = 'none';
            document.getElementById('infoYearSelect'+that.id).style.display = 'inline-flex';
        }else {
            document.getElementById('infoMonthSelect'+that.id).style.display = 'none';
            document.getElementById('infoYearSelect'+that.id).style.display = 'none';
            commonFilter(that, headerid,'month');
        }
       
    }

    

        async function commonFilter(that, headerid, type) {

            let yearname = that.idyear== undefined? '': that.idyear
            let monthname = that.idmonth== undefined? '': that.idmonth
        try {
            
            let reqData = {
                method: 'GET',
                url: `${mainAjax.baseUrl}/cms/getByheaderId?headerid=` + headerid +`&year=`+yearname+`&month=`+monthname,
                headers: {
                    'Content-Type': 'application / json'
                },
            };
            await mainAjax.resolveRequestData(reqData).then(function (result) {
                // $scope.progressbar.complete();
                console.log(result.data.data)

                $scope.downloadlist.forEach((element) => {
                console.log(element);
                if(element.headerid == headerid){
                    element.subheader = '';
                    element.subheader = result.data.data;
                }
                });
               
             
            }, function (error) {
                Notification.error({
                    message: 'Oops! something broken'
                });
            });
        } catch (e) {
            Notification.error({
                message: 'Oops! something broken'
            });
        }
    }


    // $scope.searchDetailsfunc = () =>{
        $scope.searchDetailsfunc = function () {
        let searchDetails = document.getElementById('searchDetails');
        console.log(searchDetails)
        console.log($('#searchDetails').val())
    }


    // function searchDetailsfuncs(){
        $scope.searchDetailsfuncs =  () => {
            console.log($('#searchDetails').val())
        // var regex = new RegExp($scope.downloadlist, "i");
        var regex = new RegExp($('#searchDetails').val(), "i");
			  $.each($scope.downloadlistLocal, function(key, val){
                   
				if ((val.title.search(regex) != -1) ) {
                    // $scope.downloadlist = val
				}
			  });
			 

    }
    
}])
// .filter('startFrom', function() {
//     return function(input, start) {
//         console.log(input)
//         if(!!input){
//             console.log(start)
//             start = +start; //parse to int
//             return input.slice(start);
//         }
//     }
// })
